<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="header">
        <div @click="$router.push('/sports')" class="back">
          < Torna agli sport
        </div>
        <div class="title">Seleziona i tuoi obiettivi</div>
        <div class="wishlist">
          <span @click="toWishListPage">my Wishlist</span>
          <div v-if="wishList.length" class="counter">
            {{ wishList.length }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="text-center">
          Sport:
          <span v-for="(sport, index) in selectedSports" :key="index">
            <span v-if="index > 0">, </span><span>{{ sport.name }}</span>
          </span>
        </div>
        <div class="grid">
          <div
            v-for="(item, index) in aims"
            :key="index"
            class="grid-item"
            :class="item.isSelected ? 'selected' : ''"
            @click="toggleAim(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <!--<b-button class="" @click="getProducts">getProducts</b-button>-->
      </div>
      <div class="footer">
        <b-button v-if="selectedAims.length" block @click="showProducts()"
          >CONTINUA</b-button
        >
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'sports-page',
  components: {},
  created() {
    // this.getAims()
  },
  computed: {
    ...mapGetters({
      aims: 'categories/aims',
      wishList: 'products/wishList',
      selectedSports: 'categories/selectedSports',
      selectedAims: 'categories/selectedAims',
      selectedSportIds: 'categories/selectedSportIds',
      selectedAimIds: 'categories/selectedAimIds',
    }),
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    ...mapMutations({
      toggleAim: 'categories/toggleAim',
    }),
    ...mapActions({
      getProducts: 'products/getProducts',
    }),
    onKeyDown({ code }) {
      if (code === 'Enter') {
        this.showProducts();
      }
    },
    showProducts() {
      this.getProducts().then(() => {
        this.$router.push('/products');
      });
    },
    toWishListPage() {
      if (this.wishList.length > 0) {
        this.$router.push('/wishlist');
      }
    },
  },
};
</script>

<style lang="scss">
.grid {
  .grid-item {
    &.selected {
      border: 2px solid #3a61de;
    }
  }
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
