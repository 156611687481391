<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="step-bar">
        <!--<div class="text-primary step-number">-->
        <!--  <small>OBIETTIVO</small>-->
        <!--</div>-->
        <div class="step-title text-uppercase">{{ selectedTargetName }}</div>
      </div>
      <div class="content">
        <div class="container">
          <swiper class="swiper" ref="swiper" :options="swiperOption">
            <swiper-slide v-for="product in products" :key="product.id">
              <b-card
                @click="onProductClick(product)"
                class="card-product card-clickable"
                :class="
                  selectedProduct && selectedProduct.id === product.id
                    ? 'selected'
                    : ''
                "
              >
                <div>
                  <img
                    :src="product.imageFileUrl || product.image"
                    alt=""
                    class="product-image"
                  />
                </div>
                <div class="product-title">{{ product.name }}</div>
              </b-card>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <div
            class="swiper-button-prev"
            slot="button-prev"
            @click="slidePrev"
          ></div>
          <div
            class="swiper-button-next"
            slot="button-next"
            @click="slideNext"
          ></div>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="left">
          <div class="prev-button" @click="$router.push('/catalog-target')">
            <span>INDIETRO</span>
          </div>
        </div>
        <div class="center">
          <timer v-if="isDesktopApp" />
        </div>
        <div class="right"></div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Timer from '@/components/Timer';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

export default {
  name: 'Catalog',
  components: {
    Timer,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        breakpoints: {
          768: {
            slidesPerView: 4,
            slidesPerColumn: 3,
            spaceBetween: 30,
          },
        },
        slidesPerView: 2,
        slidesPerColumn: 3,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedProduct: 'products/selectedProduct',
      selectedTargetName: 'wizard/targetName',
      products: 'products/productsByTarget',
    }),
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters['wizard/target']) {
        next();
      } else {
        next('/landing');
      }
    });
  },
  methods: {
    ...mapMutations({
      selectProduct: 'products/selectProduct',
    }),
    ...mapActions({
      getSports: 'categories/getSports',
    }),
    onProductClick(product) {
      this.selectProduct(product);
      if (this.selectedProduct) {
        this.getSports()
          .then(() => {
            this.$router.push('/catalog-product');
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    slidePrev() {
      this.$refs.swiper.$swiper.slidePrev();
    },
    slideNext() {
      this.$refs.swiper.$swiper.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  height: 520px;
  margin-left: auto;
  margin-right: auto;
  .swiper-slide {
    height: 150px;
  }
}
.card {
  &.card-product {
    height: 100%;
    .card-body {
      display: block;
      padding: 0;
      .product-image {
        width: 100px;
      }
      .product-title {
        font-size: 0.86rem;
        padding: 0 10px;
        max-height: 40px;
        overflow: hidden;
      }
    }
  }
}
.swiper-button-prev {
  top: 46%;
  &:after {
    content: '';
    display: inline-block;
    border-color: transparent #ef7d00;
    border-style: solid;
    border-width: 28px 28px 28px 0;
    height: 0;
    width: 0;
  }
}
.swiper-button-next {
  top: 46%;
  &:after {
    content: '';
    display: inline-block;
    border-color: transparent #ef7d00;
    border-style: solid;
    border-width: 28px 0 28px 28px;
    height: 0;
    width: 0;
    // margin-left: 20px;
  }
}
</style>
