<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="step-bar">
        <div class="d-flex align-items-center text-primary step-number">
          <small>STEP</small> <span class="">5/11</span>

          <a
            v-if="!isDesktopApp"
            href="https://www.namedsport.com"
            class="ml-auto"
          >
            <img src="@/assets/home.png" alt="" width="52" height="52" />
          </a>
        </div>
        <div class="step-title">SEI ALLERGICO?</div>
      </div>
      <div class="content">
        <div class="container">
          <b-row>
            <b-col cols="6" class="mb-4">
              <b-card
                class="card-md card-clickable"
                :class="hasAllergy === true ? 'selected' : ''"
                @click="selectHasAllergy(true)"
              >
                SI
              </b-card>
            </b-col>
            <b-col cols="6" class="mb-4">
              <b-card
                class="card-md card-clickable"
                :class="hasAllergy === false ? 'selected' : ''"
                @click="selectHasAllergy(false)"
              >
                NO
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="left">
          <div
            v-if="hasIntolerance"
            class="prev-button"
            @click="$router.push('/intolerance')"
          >
            <span>INDIETRO</span>
          </div>
          <div
            v-else
            class="prev-button"
            @click="$router.push('/intolerance-dialog')"
          >
            <span>INDIETRO</span>
          </div>
        </div>
        <div class="center">
          <timer v-if="isDesktopApp" />
        </div>
        <div class="right">
          <div
            class="next-button"
            :class="hasAllergy !== null ? 'active' : ''"
            @click="toNextStep"
          >
            <span>CONTINUA</span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Timer from '@/components/Timer';

export default {
  name: 'AllergyDialog',
  components: {
    Timer,
  },
  computed: {
    ...mapGetters({
      hasAllergy: 'wizard/hasAllergy',
      hasIntolerance: 'wizard/hasIntolerance',
    }),
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters['wizard/sex']) {
        next();
      } else {
        next('/landing');
      }
    });
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    ...mapActions({
      selectHasAllergy: 'wizard/selectHasAllergy',
    }),
    onKeyDown({ code }) {
      if (code === 'Enter') {
        this.toNextStep();
      }
    },
    toNextStep() {
      if (this.hasAllergy !== null) {
        if (this.hasAllergy === true) {
          this.$router.push('/allergy');
        } else {
          this.$router.push('/sport');
        }
      }
    },
  },
};
</script>
