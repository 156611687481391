import router from '@/router';

const state = {
  mainCount: 0,
  idleCount: 0,
};

const getters = {
  idleCount: (state) => state.idleCount,
  mainTimer: (state) => {
    let minutes = Math.trunc(state.mainCount / 60);
    let seconds = Math.trunc(state.mainCount % 60);

    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return minutes + ':' + seconds;
  },
};

const mutations = {
  resetIdle(state) {
    state.idleCount = 0;
  },
  incrementIdle(state) {
    state.idleCount++;
  },
  resetMain(state) {
    state.mainCount = 0;
  },
  incrementMain(state) {
    state.mainCount++;
  },
};

const actions = {
  updateIdle({ state, commit }) {
    if (state.idleCount < 60) {
      commit('incrementIdle');
    } else {
      commit('resetIdle');
      router.push('/');
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
