<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="step-bar">
        <div class="d-flex align-items-center text-primary step-number">
          <small>STEP</small> <span class="">6/11</span>

          <a
            v-if="!isDesktopApp"
            href="https://www.namedsport.com"
            class="ml-auto"
          >
            <img src="@/assets/home.png" alt="" width="52" height="52" />
          </a>
        </div>
        <div class="step-title">CHE SPORT FAI?</div>
      </div>
      <div class="content">
        <div class="container container-small">
          <b-row>
            <b-col
              v-for="(item, i) in items"
              :key="i"
              cols="6"
              md="3"
              class="mb-4"
            >
              <b-card
                @click="selectSport(item)"
                class="card-sm card-clickable"
                :class="sportName === item.name ? 'selected' : ''"
              >
                <img
                  :src="
                    require(`../assets/sports/${item.name
                      .replace(/ /g, '')
                      .toLowerCase()}.png`)
                  "
                  :alt="item.name"
                  class="sport-image"
                />
                <div class="sport-title">{{ item.name }}</div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="left">
          <div class="prev-button" @click="$router.push('/allergy-dialog')">
            <span>INDIETRO</span>
          </div>
        </div>
        <div class="center">
          <timer v-if="isDesktopApp" />
        </div>
        <div class="right">
          <div
            class="next-button"
            :class="sportName ? 'active' : ''"
            @click="toNextStep"
          >
            <span>CONTINUA</span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Timer from '@/components/Timer';

export default {
  name: 'sports-page',
  components: {
    Timer,
  },
  data() {
    return {
      items: [
        {
          name: 'Ciclismo',
          id: 7,
        },
        {
          name: 'Running',
          id: 9,
        },
        {
          name: 'Sport di Squadra',
          id: 8,
        },
        {
          name: 'Mountain Bike',
          id: 7,
        },
        {
          name: 'Fitness',
          id: 31,
        },
        {
          name: 'Body Building',
          id: 83,
        },
        {
          name: 'Tennis',
          id: 8,
        },
        {
          name: 'Nuoto',
          id: 85,
        },
        {
          name: 'CrossFit',
          id: 10,
        },
        {
          name: 'Triathlon',
          id: 84,
        },
        {
          name: 'Sci',
          id: 9,
        },
        {
          name: 'Altri Sport',
          id: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      sport: 'wizard/sport',
      sportName: 'wizard/sportName',
    }),
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters['wizard/sex']) {
        next();
      } else {
        next('/landing');
      }
    });
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    ...mapActions({
      selectSport: 'wizard/selectSport',
    }),
    onKeyDown({ code }) {
      if (code === 'Enter') {
        this.toNextStep();
      }
    },
    toNextStep() {
      if (this.sportName) {
        if (
          ['Ciclismo', 'Running', 'Sport di Squadra', 'Altri Sport'].includes(
            this.sportName
          )
        ) {
          this.$router.push('/sub-sport');
        } else {
          this.$router.push('/target');
        }
      }
    },
  },
};
</script>
