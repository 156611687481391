<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="step-bar">
        <div class="d-flex align-items-center text-primary step-number">
          <small>STEP</small> <span class="">11/11</span>

          <a
            v-if="!isDesktopApp"
            href="https://www.namedsport.com"
            class="ml-auto"
          >
            <img src="@/assets/home.png" alt="" width="52" height="52" />
          </a>
        </div>
        <div class="step-title text-uppercase">INSERISCI I TUOI DATI</div>
      </div>
      <div class="content" :class="visible ? 'has-keyboard' : ''">
        <div class="container">
          <b-form @submit.prevent="onSubmit" class="user-form">
            <b-row>
              <b-col cols="12" md="4">
                <b-form-group
                  id="input-group-name"
                  label="NOME"
                  label-for="input-name"
                >
                  <b-form-input
                    id="input-name"
                    v-model="form.name"
                    type="text"
                    name="name"
                    required
                    @focus="show"
                    data-layout="normal"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group
                  id="input-group-phone"
                  label="N° TELEFONO"
                  label-for="input-phone"
                >
                  <b-form-input
                    id="input-phone"
                    v-model="form.phone"
                    v-validate="{
                      regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                    }"
                    name="phone"
                    @focus="show"
                    data-layout="normal"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="input-email-feedback"
                    :class="errors.has('phone') ? 'd-block' : ''"
                  >
                    Per favore verifica la correttezza del telefono
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group
                  id="input-group-email"
                  label="Email"
                  label-for="input-email"
                >
                  <b-form-input
                    id="input-email"
                    v-model="form.email"
                    type="email"
                    v-validate="'required|email'"
                    name="email"
                    required
                    @focus="show"
                    data-layout="normal"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="input-email-feedback"
                    :class="errors.has('email') ? 'd-block' : ''"
                  >
                    Per favore verifica la correttezza dell'email
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <b-form-checkbox
                  id="checkbox-accept"
                  v-model="form.checked"
                  class="mt-2"
                  name="accept"
                >
                  Acconsento al trattamento dei miei dati personali
                </b-form-checkbox>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-button
                  :disabled="!formValid"
                  type="submit"
                  variant="primary"
                  size="lg"
                  >CONFERMA</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
      <vue-touch-keyboard
        :options="options"
        v-if="visible"
        :layout="layout"
        :cancel="hide"
        :accept="accept"
        :next="next"
        :input="input"
      />
    </main>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapMutations } from 'vuex';

import VueTouchKeyboard from 'vue-touch-keyboard';
import 'vue-touch-keyboard/dist/vue-touch-keyboard.css';

Vue.use(VueTouchKeyboard);

export default {
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        checked: false,
      },
      visible: false,
      layout: 'normal',
      input: null,
      options: {
        useKbEvents: false,
        preventClickEvent: false,
      },
    };
  },
  computed: {
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
    formValid() {
      return (
        this.form.name &&
        !this.errors.has('phone') &&
        this.form.email &&
        this.form.checked &&
        !this.errors.has('email')
      );
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters['wizard/sex']) {
        next();
      } else {
        next('/landing');
      }
    });
  },
  methods: {
    ...mapMutations({
      setUser: 'wizard/setUser',
    }),
    onSubmit() {
      if (this.formValid) {
        this.setUser({
          name: this.form.name,
          phone: this.form.phone,
          email: this.form.email,
        });
        this.$router.push('/print');
      }
    },
    accept() {
      this.hide();
    },

    show(e) {
      if (process.env.VUE_APP_DESTINATION === 'app') {
        this.input = e.target;
        this.layout = e.target.dataset.layout;

        if (!this.visible) {
          this.visible = true;
        }
      }
    },

    hide() {
      this.visible = false;
    },
    next() {
      let inputs = document.querySelectorAll('input.form-control');
      let found = false;
      [].forEach.call(inputs, (item, i) => {
        if (!found && item === this.input && i < inputs.length - 1) {
          found = true;
          this.$nextTick(() => {
            inputs[i + 1].focus();
          });
        }
      });
      if (!found) {
        this.input.blur();
        this.hide();
      }
    },
  },
};
</script>
