function generateImage(product) {
  return `
    <div
      class="img-container center autowidth"
      align="center"
      style="padding-right: 0px;padding-left: 0px;"
    >
      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><!
      [endif]--><img
        class="center autowidth"
        align="center"
        border="0"
        src="${product.image}"
        alt="${product.title}"
        title="${product.title}"
        style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 300px; display: block;"
        width="300"
      />
    
      <!--[if mso]></td></tr></table><![endif]-->
    </div>
  `;
}

function generateDescription(product) {
  return `
    <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->

    <div
      style="color:#555555;font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;min-height:280px;"
    >
      <div
        style="line-height: 1.2; font-size: 12px; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; color: #555555; mso-line-height-alt: 14px;"
      >
        <br />
        <br />
    
        <p
          style="text-align: center; line-height: 1.2; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 14px; margin: 0;"
        >
          <span style="color: #000000;"
            ><strong
              ><span style="font-size: 14px;"
                >${product.title}</span
              ></strong
            ></span
          >
        </p>
    
        <br />
        <br />

        <p
          style="line-height: 1.2; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; mso-line-height-alt: 17px; margin: 0;"
        >
          ${product.description
            .replace(
              /<p>&gt;/g,
              '<p style="margin: 0"><span style="color: #ff6600;"><strong>&gt;</strong></span>'
            )
            .replace(
              /<li>/g,
              '<li style="list-style-type: none;"><span style="color: #ff6600;"><strong>&gt;&nbsp;</strong></span>'
            )
            .replace(/\r\n/g, '')
            // eslint-disable-next-line no-irregular-whitespace
            .replace(/<p> <\/p>/g, '')}
        </p>
      </div>
    </div>
  `;
}

function generateProduct(product) {
  return `   
    <div style="background-color:transparent;">
        <div
            className="block-grid two-up no-stack"
            style="Margin: 0 auto; min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;"
        >
            <div
                style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;"
            >
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->

                <!--[if (mso)|(IE)]><td align="center" width="300" style="background-color:transparent;width:300px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->

                <div
                    className="col num6"
                    style="max-width: 320px; min-width: 300px; display: table-cell; vertical-align: top; width: 300px;"
                >
                    <div className="col_cont" style="width:100% !important;">
                        <!--[if (!mso)&(!IE)]><!-->

                        <div
                            style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;"
                        >
                            <!--<![endif]-->

                            ${generateImage(product)}

                            <!--[if (!mso)&(!IE)]><!-->
                        </div>

                        <!--<![endif]-->
                    </div>
                </div>

                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->

                <!--[if (mso)|(IE)]></td><td align="center" width="300" style="background-color:transparent;width:300px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->

                <div
                    className="col num6"
                    style="max-width: 320px; min-width: 300px; display: table-cell; vertical-align: top; width: 300px;"
                >
                    <div className="col_cont" style="width:100% !important;">
                        <!--[if (!mso)&(!IE)]><!-->

                        <div
                            style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;"
                        >
                            <!--<![endif]-->

                            ${generateDescription(product)}

                            <!--[if (!mso)&(!IE)]><!-->
                        </div>

                        <!--<![endif]-->
                    </div>
                </div>

                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->

                <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
            </div>
        </div>
    </div>
        <div style="background-color:transparent;">
                <div class="block-grid " style="min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
                    <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
                        <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:480px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                        <!--[if (mso)|(IE)]><td align="center" width="480" style="background-color:transparent;width:480px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
                        <div class="col num12" style="min-width: 320px; max-width: 600px; display: table-cell; vertical-align: top; width: 480px;">
                            <div class="col_cont" style="width:100% !important;">
                                <!--[if (!mso)&(!IE)]><!-->
                                <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
                                    <!--<![endif]-->
                                    <table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
                                        <tbody>
                                        <tr style="vertical-align: top;" valign="top">
                                            <td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
                                                <table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 1px solid #BBBBBB; width: 100%;" align="center" role="presentation" valign="top">
                                                    <tbody>
                                                    <tr style="vertical-align: top;" valign="top">
                                                        <td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" valign="top"><span></span></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <!--[if (!mso)&(!IE)]><!-->
                                </div>
                                <!--<![endif]-->
                            </div>
                        </div>
                        <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                        <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                    </div>
                </div>
            </div>
      `;
}

function generateSection(code, products) {
  const section = {
    title: '',
    products: [],
  };

  if (code === 'before') {
    section.title = 'I NOSTRI SUGGERIMENTI PER IL PRIMA';
  } else if (code === 'during') {
    section.title = 'NON VA DIMENTICATO IL DURANTE';
  } else if (code === 'after') {
    section.title = 'PERFETTI PER IL DOPO';
  } else if (code === 'free') {
    section.title = 'IDEALI NEL FREE TIME';
  }

  products.forEach((product) => {
    section.products.push(generateProduct(product));
  });

  return `
    <div style="background-color:transparent;">
      <div
        class="block-grid "
        style="Margin: 0 auto; min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;"
      >
        <div
          style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;"
        >
          <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
    
          <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:transparent;width:600px; border-top: 0px solid #D7D9DA; border-left: 0px solid #D7D9DA; border-bottom: 0px solid #D7D9DA; border-right: 0px solid #D7D9DA;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 60px; padding-left: 60px; padding-top:60px; padding-bottom:0px;"><![endif]-->
    
          <div
            class="col num12"
            style="min-width: 320px; max-width: 600px; display: table-cell; vertical-align: top; width: 600px;"
          >
            <div style="width:100% !important;">
              <!--[if (!mso)&(!IE)]><!-->
    
              <div
                style="border-top:0px solid #D7D9DA; border-left:0px solid #D7D9DA; border-bottom:0px solid #D7D9DA; border-right:0px solid #D7D9DA; padding-top:30px; padding-bottom:0px; padding-right: 60px; padding-left: 60px;"
              >
                <!--<![endif]-->
    
                <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 35px; font-family: Arial, sans-serif"><![endif]-->
    
                <div
                  style="color:#5F6C72;font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;line-height:1.5;padding-top:0px;padding-right:0px;padding-bottom:35px;padding-left:0px;"
                >
                  <div
                    style="font-size: 12px; line-height: 1.5; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; color: #5F6C72; mso-line-height-alt: 18px;"
                  >
                    <br />
    
                    <p
                      style="text-align: center; line-height: 1.5; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 20px; mso-line-height-alt: 30px; margin: 0;"
                    >
                      <span style="color: #000000; font-size: 20px;"
                        ><span><strong>${section.title}:</strong></span></span
                      >
                    </p>
                  </div>
                </div>
    
                <!--[if mso]></td></tr></table><![endif]-->
    
                <!--[if (!mso)&(!IE)]><!-->
              </div>
    
              <!--<![endif]-->
            </div>
          </div>
    
          <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
    
          <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
        </div>
      </div>
    </div>
    ${section.products.join(' ')}
  `;
}

const getEmailContent = (email) => {
  const inviteText = {
    app: 'Ti invitiamo a scoprire nel punto vendita tutti i formati ed i gusti dei prodotti consigliati.',
    site: 'Ti invitiamo a scoprire sul nostro sito web tutti i formati ed i gusti dei prodotti consigliati.',
  }[process.env.VUE_APP_DESTINATION];
  return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>A_Questo è il tuo Sport Test NAMEDSPORT></title>
        <!--[if gte mso 9
          ]><xml
            ><o:OfficeDocumentSettings
              ><o:AllowPNG /><o:PixelsPerInch
                >96</o:PixelsPerInch
              ></o:OfficeDocumentSettings
            ></xml
          ><!
        [endif]-->
    
        <meta name="viewport" content="width=device-width" />
    
        <!--[if !mso]><!-->
    
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    
        <!--<![endif]-->
    
        <title></title>
    
        <!--[if !mso]><!-->
    
        <!--<![endif]-->
    
        <style type="text/css">
          body {
            margin: 0;
    
            padding: 0;
          }
    
          table,
          td,
          tr {
            vertical-align: top;
    
            border-collapse: collapse;
          }
    
          * {
            line-height: inherit;
          }
    
          a[x-apple-data-detectors="true"] {
            color: inherit !important;
    
            text-decoration: none !important;
          }
        </style>
    
        <style type="text/css" id="media-query">
          @media (max-width: 620px) {
            .block-grid,
            .col {
              min-width: 320px !important;
    
              max-width: 100% !important;
    
              display: block !important;
            }
    
            .block-grid {
              width: 100% !important;
            }
    
            .col {
              width: 100% !important;
            }
    
            .col > div {
              margin: 0 auto;
            }
    
            img.fullwidth,
            img.fullwidthOnMobile {
              max-width: 100% !important;
            }
    
            .no-stack .col {
              min-width: 0 !important;
    
              display: table-cell !important;
            }
    
            .no-stack.two-up .col {
              width: 50% !important;
            }
    
            .no-stack .col.num4 {
              width: 33% !important;
            }
    
            .no-stack .col.num8 {
              width: 66% !important;
            }
    
            .no-stack .col.num4 {
              width: 33% !important;
            }
    
            .no-stack .col.num3 {
              width: 25% !important;
            }
    
            .no-stack .col.num6 {
              width: 50% !important;
            }
    
            .no-stack .col.num9 {
              width: 75% !important;
            }
    
            .video-block {
              max-width: none !important;
            }
    
            .mobile_hide {
              min-height: 0px;
    
              max-height: 0px;
    
              max-width: 0px;
    
              display: none;
    
              overflow: hidden;
    
              font-size: 0px;
            }
    
            .desktop_hide {
              display: block !important;
    
              max-height: none !important;
            }
          }
        </style>
      </head>
    
      <body
        class="clean-body"
        style="margin: 0; padding: 0; -webkit-text-size-adjust: 100%; background-color: #f9f9f9;"
      >
        <p
          style="font-size: 12px; vertical-align: middle; color:#666666; font-family: Arial,Helvetica,sans-serif; text-align: center;"
        >
          <!--[if IE]><div class="ie-browser"><![endif]-->
        </p>
        <table
          class="nl-container"
          style="table-layout: fixed; vertical-align: top; min-width: 320px; Margin: 0 auto; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f9f9f9; width: 100%;"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
          width="100%"
          bgcolor="#f9f9f9"
          valign="top"
        >
          <tbody>
            <tr style="vertical-align: top;" valign="top">
              <td style="word-break: break-word; vertical-align: top;" valign="top">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color:#f9f9f9"><![endif]-->
    
                <div style="background-color:transparent;">
                  <div
                    class="block-grid "
                    style="Margin: 0 auto; min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;"
                  >
                    <div
                      style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;"
                    >
                      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
    
                      <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:transparent;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
    
                      <div
                        class="col num12"
                        style="min-width: 320px; max-width: 600px; display: table-cell; vertical-align: top; width: 600px;"
                      >
                        <div style="width:100% !important;">
                          <!--[if (!mso)&(!IE)]><!-->
    
                          <div
                            style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;"
                          >
                            <!--<![endif]-->
    
                            <div
                              class="img-container center fixedwidth"
                              align="center"
                              style="padding-right: 0px;padding-left: 0px;"
                            >
                              <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]-->
    
                              <div style="font-size:1px;line-height:20px">
                                &nbsp;
                              </div>
                              <a
                                href="https://www.namedsport.com/it"
                                target="_blank"
                                style="outline:none"
                                tabindex="-1"
                              >
                                <img
                                  class="center fixedwidth"
                                  align="center"
                                  border="0"
                                  src="http://namedsport.img.musvc3.net/static/107506/assets/1/Schermata%202017-09-07%20alle%2012.40.01.png"
                                  alt="Image"
                                  title="Image"
                                  style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 840px; display: block;"
                                  width="840"
                              /></a>
    
                              <div style="font-size:1px;line-height:20px">
                                &nbsp;
                              </div>
    
                              <!--[if mso]></td></tr></table><![endif]-->
                            </div>
    
                            <!--[if (!mso)&(!IE)]><!-->
                          </div>
    
                          <!--<![endif]-->
                        </div>
                      </div>
    
                      <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
    
                      <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                    </div>
                  </div>
                </div>
    
                <div style="background-color:transparent;">
                  <div
                    class="block-grid "
                    style="Margin: 0 auto; min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;"
                  >
                    <div
                      style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;"
                    >
                      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
    
                      <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:transparent;width:600px; border-top: 0px solid #D7D9DA; border-left: 0px solid #D7D9DA; border-bottom: 0px solid #D7D9DA; border-right: 0px solid #D7D9DA;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 60px; padding-left: 60px; padding-top:60px; padding-bottom:0px;"><![endif]-->
    
                      <div
                        class="col num12"
                        style="min-width: 320px; max-width: 600px; display: table-cell; vertical-align: top; width: 600px;"
                      >
                        <div style="width:100% !important;">
                          <!--[if (!mso)&(!IE)]><!-->
    
                          <div
                            style="border-top:0px solid #D7D9DA; border-left:0px solid #D7D9DA; border-bottom:0px solid #D7D9DA; border-right:0px solid #D7D9DA; padding-top:60px; padding-bottom:0px; padding-right: 60px; padding-left: 60px;"
                          >
                            <!--<![endif]-->
    
                            <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 35px; font-family: Arial, sans-serif"><![endif]-->
    
                            <!--[if mso]></td></tr></table><![endif]-->
    
                            <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Arial, sans-serif"><![endif]-->
    
                            <div
                              style="color:#5F6C72;font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;line-height:1.5;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;"
                            >
                              <div
                                style="font-size: 12px; line-height: 1.5; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; color: #5F6C72; mso-line-height-alt: 18px;"
                              >
                                <p
                                  style="font-size: 14px; line-height: 1.5; text-align: center; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 21px; margin: 0;"
                                >
                                  &nbsp;
                                </p>
    
                                <p
                                  style="line-height: 1.5; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; mso-line-height-alt: 21px; margin: 0;"
                                >
                                  <span style="color: #000000; font-size: 14px;"
                                    >Gentile Cliente,&nbsp;</span
                                  >
                                </p>
    
                                <p
                                  style="line-height: 1.5; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; mso-line-height-alt: 21px; margin: 0;"
                                >
                                  <span style="color: #000000; font-size: 14px;"
                                    >grazie per aver completato lo Sport Test
                                    NAMEDSPORT&gt;.&nbsp;</span
                                  >
                                </p>
    
                                <p
                                  style="line-height: 1.5; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 18px; margin: 0;"
                                >
                                  &nbsp;
                                </p>
    
                                <p
                                  style="line-height: 1.5; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; mso-line-height-alt: 21px; margin: 0;"
                                >
                                  <span style="color: #000000; font-size: 14px;"
                                    >Sulla base delle informazioni che ci hai fornito
                                    e considerando le tue caratteristiche personali,
                                    lo sport praticato, il numero medio e la
                                    durata dei tuoi allenamenti, potrai trovare di
                                    seguito la lista dei prodotti che più si
                                    addicono alle tue esigenze.&nbsp;</span
                                  >
                                </p>

                                <p
                                  style="line-height: 1.5; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 18px; margin: 0;"
                                >
                                  &nbsp;
                                </p>

                                <p
                                  style="line-height: 1.5; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; mso-line-height-alt: 21px; margin: 0;"
                                >
                                  <span style="color: #000000; font-size: 14px;"
                                    >Sei curioso di provarli?
                                    Ti abbiamo riservato un codice coupon per
                                    ottenere le&nbsp;<b>spedizioni gratuite*</b>.
                                    Inserisci il codice&nbsp;<span style="color: #ff8200; font-size: 14px;"
                                    ><b>TASTEIT</b></span>&nbsp;nell’apposito
                                    box prima di procedere al checkout.&nbsp;</span
                                  >
                                </p>

                                <p
                                  style="line-height: 1.5; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 18px; margin: 0;"
                                >
                                  &nbsp;
                                </p>
                                
                                <p
                                  style="line-height: 1.5; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; mso-line-height-alt: 21px; margin: 0;"
                                >
                                  <span style="color: #000000; font-size: 12px;"
                                    >*spesa minima di acquisto 19,99€&nbsp;<br />&nbsp;</span
                                  >
                                </p>

                                <p
                                  style="line-height: 1.5; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 18px; margin: 0;"
                                >
                                  &nbsp;
                                </p>
    
                                <p
                                  style="text-align: center; line-height: 1.5; word-break: break-word; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; mso-line-height-alt: 21px; margin: 0;"
                                >
                                  <span style="color: #000000; font-size: 14px;">
                                    <strong>Sport</strong>: ${email.sport}<br />
                                    <strong>Obiettivo</strong>: ${email.goal}
                                  </span>
                                </p>
                              </div>
                            </div>
    
                            <!--[if mso]></td></tr></table><![endif]-->
    
                            <!--[if (!mso)&(!IE)]><!-->
                          </div>
    
                          <!--<![endif]-->
                        </div>
                      </div>
    
                      <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
    
                      <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                    </div>
                        <table
                      class="divider"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      style="margin-top: 30px; table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;"
                      role="presentation"
                      valign="top"
                    >
                      <tbody>
                        <tr style="vertical-align: top;" valign="top">
                          <td
                            class="divider_inner"
                            style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;"
                            valign="top"
                          >
                            <table
                              class="divider_content"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                              style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 1px solid #BBBBBB; width: 100%;"
                              align="center"
                              role="presentation"
                              valign="top"
                            >
                              <tbody>
                                <tr style="vertical-align: top;" valign="top">
                                  <td
                                    style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;"
                                    valign="top"
                                  >
                                    <span></span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
    
                ${
                  email.beforeProducts && email.beforeProducts.length > 0
                    ? generateSection('before', email.beforeProducts)
                    : ''
                }
                
                ${
                  email.duringProducts && email.duringProducts.length > 0
                    ? generateSection('during', email.duringProducts)
                    : ''
                }
                
                ${
                  email.afterProducts && email.afterProducts.length > 0
                    ? generateSection('after', email.afterProducts)
                    : ''
                }
                
                ${
                  email.freeTimeProducts && email.freeTimeProducts.length > 0
                    ? generateSection('free', email.freeTimeProducts)
                    : ''
                }
    
                <div style="background-color:transparent;">
                  <div
                    class="block-grid "
                    style="Margin: 0 auto; min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;"
                  >
                    <div
                      style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;"
                    >
                      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
    
                      <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:transparent;width:600px; border-top: 0px solid #D7D9DA; border-left: 0px solid #D7D9DA; border-bottom: 0px solid #D7D9DA; border-right: 0px solid #D7D9DA;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 60px; padding-left: 60px; padding-top:60px; padding-bottom:0px;"><![endif]-->
    
                      <div
                        class="col num12"
                        style="min-width: 320px; max-width: 600px; display: table-cell; vertical-align: top; width: 600px;"
                      >
                        <div style="width:100% !important;">
                          <!--[if (!mso)&(!IE)]><!-->
    
                          <div
                            style="border-top:0px solid #D7D9DA; border-left:0px solid #D7D9DA; border-bottom:0px solid #D7D9DA; border-right:0px solid #D7D9DA; padding-top:10px; padding-bottom:0px; padding-right: 60px; padding-left: 60px;"
                          >
                            <!--<![endif]-->
    
                            <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 35px; font-family: Arial, sans-serif"><![endif]-->
    
                            <div
                              style="color:#5F6C72;font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;line-height:1.5;padding-top:0px;padding-right:0px;padding-bottom:35px;padding-left:0px;"
                            >
                              <div
                                style="font-size: 12px; line-height: 1.5; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; color: #5F6C72; mso-line-height-alt: 18px;"
                              >
                                <p
                                  style="font-size: 14px; line-height: 1.5; word-break: break-word; text-align: center; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 21px; margin: 0;"
                                >
                                  &nbsp;
                                </p>
    
                                <p
                                  style="line-height: 1.5; word-break: break-word; text-align: center; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 18px; margin: 0;"
                                >
                                  <span style="color: #000000;">
                                  ${inviteText}  
                                  </span>
                                </p>
    
                                <p
                                  style="line-height: 1.5; word-break: break-word; text-align: center; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 18px; margin: 0;"
                                >
                                  <span style="color: #000000;">
                                  Per rimanere aggiornato e ricevere suggerimenti su alimentazione ed allenamento, visita il blog:
                                    <a
                                      style="text-decoration: underline; color: #f68b44;"
                                      href="http://www.stay-trained.com"
                                      target="_blank"
                                      rel="noopener"
                                      >www.stay-trained.com</a
                                    ></span
                                  >
                                </p>
                              </div>
                            </div>
    
                            <!--[if mso]></td></tr></table><![endif]-->
    
                            <!--[if (!mso)&(!IE)]><!-->
                          </div>
    
                          <!--<![endif]-->
                        </div>
                      </div>
    
                      <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
    
                      <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                    </div>
                  </div>
                </div>
    
                <div style="background-color:transparent;">
                  <div
                    class="block-grid "
                    style="Margin: 0 auto; min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: #000000;"
                  >
                    <div
                      style="border-collapse: collapse;display: table;width: 100%;background-color:#000000;"
                    >
                      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#000000"><![endif]-->
    
                      <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#000000;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
    
                      <div
                        class="col num12"
                        style="min-width: 320px; max-width: 600px; display: table-cell; vertical-align: top; width: 600px;"
                      >
                        <div style="width:100% !important;">
                          <!--[if (!mso)&(!IE)]><!-->
    
                          <div
                            style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;"
                          >
                            <!--<![endif]-->
    
                            <table
                              class="social_icons"
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                              role="presentation"
                              style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              valign="top"
                            >
                              <tbody>
                                <tr style="vertical-align: top;" valign="top">
                                  <td
                                    style="word-break: break-word; vertical-align: top; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;"
                                    valign="top"
                                  >
                                    <table
                                      class="social_table"
                                      align="center"
                                      cellpadding="0"
                                      cellspacing="0"
                                      role="presentation"
                                      style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-tspace: 0; mso-table-rspace: 0; mso-table-bspace: 0; mso-table-lspace: 0;"
                                      valign="top"
                                    >
                                      <tbody>
                                        <tr
                                          style="vertical-align: top; display: inline-block; text-align: center;"
                                          align="center"
                                          valign="top"
                                        >
                                          <td
                                            style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 2.5px; padding-left: 2.5px;"
                                            valign="top"
                                          >
                                            <a
                                              href="http://namedsport.musvc3.net/e/t?q=6%3dBZMXKV%262%3dW%26y%3dULT%26z%3dQJaHVO%26N%3dsJ0I4_Hgva_Sq_Kbsf_Uq_Hgva_RvP8M.v4n5rHzA.sHx_Hgva_RvGlCu74F5K5_Hgva_SvN5C_9H6Hs8_3qis_D49IzH0MpI0_Kbsf_UGK0F_x5tB6C_8tdp_I7pCqBw_Hgva_RLN5C_s4xFqBrD_8tdp_I7nE49_tIsK_dtH%267%3dxK9OnS.485%2699%3dWF4l1qWO"
                                              target="_blank"
                                              ><img
                                                width="32"
                                                height="32"
                                                src="http://namedsport.img.musvc3.net/static/107506/images/social/circle-dark-gray/facebook@2x.png"
                                                alt="Facebook"
                                                title="Facebook"
                                                style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"
                                            /></a>
                                          </td>
    
                                          <td
                                            style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 2.5px; padding-left: 2.5px;"
                                            valign="top"
                                          >
                                            <a
                                              href="http://namedsport.musvc3.net/e/t?q=8%3dGb7ZPX%26l%3dY%264%3dW6V%265%3dS4cMX9%26P%3dxLtK_8sSu_I3_rvir_2A_8sSu_H8tRyLt08.5oH_8sSu_H8n637dN6GrO_8sSu_I8uO3_KoP85e_MguP_XtKpJ86q3aLt09L_rvir_2aALm_Hu6iP3_JQxf_Ud033iG_8sSu_HXuO3_5aH63iB4_JQxf_Ud85Ff_D95r_xjT%26d%3dHAKv8I.FeO%26yK%3d4ZMa"
                                              target="_blank"
                                              ><img
                                                width="32"
                                                height="32"
                                                src="http://namedsport.img.musvc3.net/static/107506/images/social/circle-dark-gray/twitter@2x.png"
                                                alt="Twitter"
                                                title="Twitter"
                                                style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"
                                            /></a>
                                          </td>
    
                                          <td
                                            style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 2.5px; padding-left: 2.5px;"
                                            valign="top"
                                          >
                                            <a
                                              href="http://namedsport.musvc3.net/e/t?q=7%3dNhNYWd%263%3dX%26A%3dcMU%26B%3dYKbTdP%26O%3d5RAJF_Phwm_ar_Ln1g_V3_Phwm_ZwCAQA54PrG.zM4_Ln1g_V3LrG2B0JBPA_Ln1g_W3SAG_FMBLzC_9upx_J8F5x9rN6LGRvMG_Phwm_ZMOGK_491GBG_Eyjt_PBvGxG3_Ln1g_VSSAG_z94JxGxH_Eyjt_PBtIAD_zMzP_jxO%26B%3d4OFTtW.ACA%26CF%3dbLaV"
                                              target="_blank"
                                              ><img
                                                width="32"
                                                height="32"
                                                src="http://namedsport.img.musvc3.net/static/107506/images/social/circle-dark-gray/instagram@2x.png"
                                                alt="Instagram"
                                                title="Instagram"
                                                style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"
                                            /></a>
                                          </td>
    
                                          <td
                                            style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 2.5px; padding-left: 2.5px;"
                                            valign="top"
                                          >
                                            <a
                                              href="http://namedsport.musvc3.net/e/t?q=3%3d1hAU0d%26p%3dT%26n%3dc0Q%26o%3dY8X7dC%26K%3dhRxFs_PUsP_ae_HQ1T_Rf_PUsP_Zj9t.JmDkCh9n.AsC_ryWp_2DgEmNeDy_PUsP_ZjDaKi4sNsHt_PUsP_ajKtK_wEuPg5_ryWp_3BwFoPxJeQx_HQ1T_R6SxC_mCh9uK_vqSx_74eKe9l_PUsP_Z0KtK_g1mNe9gL_vqSx_74cMr6_iQgH_S26%264%3dmS1a9ewLca.r5t%26Gw%3dT5eC"
                                              target="_blank"
                                              ><img
                                                width="32"
                                                height="32"
                                                src="http://namedsport.img.musvc3.net/static/107506/images/social/circle-dark-gray/linkedin@2x.png"
                                                alt="LinkedIn"
                                                title="LinkedIn"
                                                style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"
                                            /></a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
    
                            <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
    
                            <div
                              style="color:#555555;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;"
                            >
                              <div
                                style="line-height: 1.2; font-size: 12px; color: #555555; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;"
                              >
                                <p
                                  style="text-align: center; line-height: 1.2; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"
                                >
                                  &nbsp;
                                </p>
    
                                <p
                                  style="text-align: center; line-height: 1.2; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"
                                >
                                  &nbsp;
                                </p>
    
                                <p
                                  style="text-align: center; line-height: 1.2; word-break: break-word; font-size: 17px; mso-line-height-alt: 20px; mso-ansi-font-size: 18px; margin: 0;"
                                >
                                  <span
                                    style="color: #ffffff; font-size: 17px; mso-ansi-font-size: 18px;"
                                    ><strong
                                      >Servizio Clienti: 800 20 36 78 da Lun-Ven
                                      dalle 14.00 alle 17.00</strong
                                    ></span
                                  >
                                </p>
    
                                <p
                                  style="text-align: center; line-height: 1.2; word-break: break-word; font-size: 16px; mso-line-height-alt: 19px; margin: 0;"
                                >
                                  <span style="font-size: 16px;"
                                    >email:
                                    <span style="color: #ff6600;"
                                      ><a
                                        style="text-decoration: underline; color: #ff6600;"
                                        title="contactus@namedsport.com"
                                        href="http://namedsport.musvc3.net/e/t?q=0%3dJfQbSb%266%3da%267%3daPX%268%3dWNePbS%26R%3d673ICK_Bxlv_M8vK8Qt9DRB_Nkzi_ZTKtIyABL9OC.99J_Awmw_LBEQ6_O9RA9y_Ojyj_ZwO0LAPDBBP_Bxlv_L8t7ucDP7_Jx03R6_Nkzi_YxB673I_Awmw_KbEQ6_9uJ973D7_Nkzi_Yx08Jz_FB9B_zmX%26x%3dJDOF0L.JyQ%262O%3dNbPe"
                                        >contactus@namedsport.com</a
                                      ></span
                                    ></span
                                  >
                                </p>
    
                                <p
                                  style="text-align: center; line-height: 1.2; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"
                                >
                                  &nbsp;
                                </p>
                              </div>
                            </div>
    
                            <!--[if mso]></td></tr></table><![endif]-->
    
                            <!--[if (!mso)&(!IE)]><!-->
                          </div>
    
                          <!--<![endif]-->
                        </div>
                      </div>
    
                      <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
    
                      <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                    </div>
                  </div>
                </div>
    
                <div style="background-color:transparent;">
                  <div
                    class="block-grid "
                    style="Margin: 0 auto; min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: #000000;"
                  >
                    <div
                      style="border-collapse: collapse;display: table;width: 100%;background-color:#000000;"
                    >
                      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#000000"><![endif]-->
    
                      <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#000000;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
    
                      <div
                        class="col num12"
                        style="min-width: 320px; max-width: 600px; display: table-cell; vertical-align: top; width: 600px;"
                      >
                        <div style="width:100% !important;">
                          <!--[if (!mso)&(!IE)]><!-->
    
                          <div
                            style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;"
                          >
                            <!--<![endif]-->
    
                            <div
                              class="img-container center autowidth"
                              align="center"
                              style="padding-right: 0px;padding-left: 0px;"
                            >
                              <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><!
                              [endif]--><img
                                class="center autowidth"
                                align="center"
                                border="0"
                                src="${
                                  process.env.VUE_APP_DESTINATION === 'app'
                                    ? 'https://st.namedsport.com/' +
                                      'email-footer.jpeg'
                                    : window.location.origin +
                                      process.env.BASE_URL +
                                      'email-footer.jpeg'
                                }"
                                alt="Testo alternativo"
                                title="Testo alternativo"
                                style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 600px; display: block;"
                                width="600"
                              />
    
                              <!--[if mso]></td></tr></table><![endif]-->
                            </div>
    
                            <!--[if (!mso)&(!IE)]><!-->
                          </div>
    
                          <!--<![endif]-->
                        </div>
                      </div>
    
                      <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
    
                      <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                    </div>
                  </div>
                </div>
    
                <div style="background-color:transparent;">
                  <div
                    class="block-grid "
                    style="Margin: 0 auto; min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: #000000;"
                  >
                    <div
                      style="border-collapse: collapse;display: table;width: 100%;background-color:#000000;"
                    >
                      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#000000"><![endif]-->
    
                      <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#000000;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
    
                      <div
                        class="col num12"
                        style="min-width: 320px; max-width: 600px; display: table-cell; vertical-align: top; width: 600px;"
                      >
                        <div style="width:100% !important;">
                          <!--[if (!mso)&(!IE)]><!-->
    
                          <div
                            style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;"
                          >
                            <!--<![endif]-->
    
                            <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
    
                            <div
                              style="color:#555555;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;"
                            >
                              <div
                                style="line-height: 1.2; font-size: 12px; color: #555555; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;"
                              >
                                <p
                                  style="text-align: center; line-height: 1.2; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"
                                >
                                  &nbsp;
                                </p>
    
                                <p
                                  style="text-align: center; line-height: 1.2; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"
                                >
                                  &nbsp;
                                </p>
    
                                <p
                                  style="text-align: center; line-height: 1.2; word-break: break-word; font-size: 16px; mso-line-height-alt: 19px; margin: 0;"
                                >
                                  <span style="font-size: 16px;"
                                    >NAMEDSPORT&gt; S.r.l. - P.IVA 09311080965</span
                                  ><br /><span style="font-size: 16px;"
                                    >NAMEDSPORT&gt;®, NAMEDSPORT&gt; Logo® e Stay
                                    Trained!™ sono trademarks proprietà di
                                    NAMEDSPORT&gt; S.r.l.</span
                                  >
                                </p>
    
                                <p
                                  style="text-align: center; line-height: 1.2; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"
                                >
                                  &nbsp;
                                </p>
                              </div>
                            </div>
    
                            <!--[if mso]></td></tr></table><![endif]-->
    
                            <!--[if (!mso)&(!IE)]><!-->
                          </div>
    
                          <!--<![endif]-->
                        </div>
                      </div>
    
                      <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
    
                      <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                    </div>
                  </div>
                </div>
    
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
    
        <!--[if (IE)]></div><![endif]-->
        <table
          align="center"
          border="0"
          cellpadding="5"
          cellspacing="0"
          width="100%"
          style="background-color: rgb(242, 242, 242);"
          class=""
        >
          <tbody>
            <tr>
              <td>
                <img
                  src="http://namedsport.img.musvc3.net/static/107506/images/footer/trans.gif"
                  alt=" "
                  style="display: block; border-width: 0px; border-style: solid;"
                  height="20"
                />
              </td>
            </tr>
    
            <tr>
              <td align="center">
                <table border="0" cellpadding="0" cellspacing="0">
                  <tbody>
                    <tr>
                      <td
                        align="center"
                        valign="middle"
                        style="font-size:12px;font-weight:normal;color:#999999;font-family:Arial,sans-serif;"
                      ></td>
                    </tr>
    
                    <tr>
                      <td>
                        <img
                          src="http://namedsport.img.musvc3.net/static/107506/images/footer/trans.gif"
                          alt=" "
                          style="display: block; border-width: 0px; border-style: solid;"
                          height="20"
                        />
                      </td>
                    </tr>
    
                    <tr>
                      <td
                        align="center"
                        valign="middle"
                        style="font-size:12px;font-weight:normal;color:#999999;font-family:Arial,sans-serif;line-height: 16px"
                      >
                        Ricevi questa email perché ti sei registrato sul nostro
                        applicativo SportTest e hai dato il consenso a ricevere
                        comunicazioni email da parte nostra<br />
    
                        NAMED SPA, Via Lega Lombarda 33, Lesmo, 20855 Monza Brianza
                        IT<br />
    
                        www.namedsport.com 800 203678 Fabio Canova
                      </td>
                    </tr>
    
                    <tr>
                      <td>
                        <img
                          src="http://namedsport.img.musvc3.net/static/107506/images/footer/trans.gif"
                          alt=" "
                          style="display: block; border-width: 0px; border-style: solid;"
                          height="20"
                        />
                      </td>
                    </tr>
    
                    <tr>
                      <td
                        align="center"
                        style="font-size:12px;font-weight:normal;color:#999999;font-family:Arial,sans-serif;line-height:16px"
                        valign="middle"
                      >
                        Messaggio inviato con&nbsp;<a
                          href="http://namedsport.musvc3.net/e/t?q=A%3d7fEcFb%26t%3db%26t%3daDY%26u%3dWBfCbG%26S%3dnP2N_xwax_97_zyYv_0D_xwax_8Bihma6d.kIiGrOx.AuI_zyYv_0Dx_NY1V_YnSzI_1M1NkC_xwax_901NuN2R9g7ikO2_PWyX_ZBQ2K_sAlG1I_zyYv_ABkIiGr_NY1V_XDSzI_k9sLiGmJ_zyYv_ABiKvD_oOkP_Yz0%26B%3dsQ1TiY.vCz%26E1%3dbAcG"
                          target="_blank"
                          style="text-decoration:none;font-size:12px;color:#999999;font-family:Arial,sans-serif;font-weight:bold;"
                          >MailUp<sup>&reg;</sup></a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
    
            <tr>
              <td>
                <img
                  src="http://namedsport.img.musvc3.net/static/107506/images/footer/trans.gif"
                  alt=" "
                  style="display: block; border-width: 0px; border-style: solid;"
                  height="20"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <img
          src="http://namedsport.musvc3.net/e/c?q=9%3dBdKaKZ%26z%3dZ%26y%3dYJW%26z%3dUHdHZM%26D7l5o%3dB5GeHaH7-Gdt8-E5Mc-m0Gc-C5LaDUJepdse%26tM%3dHaHc%26r%3dI6M09D.HsP%265%3dUEXCXIWIcM"
          width="1"
          height="1"
        /><script type="text/javascript" src="/js/frontendPendoLoader.js"></script>
      </body>
    </html>
  `;
};

module.exports = getEmailContent;
