<template>
  <div id="wrapper">
    <main
      class="d-flex flex-column justify-content-center text-center print-page"
      style="min-height: 100vh"
      @click="goToStart"
    >
      <div class="print-page-content container py-4">
        <h1>GRAZIE PER AVER COMPLETATO<br />LO SPORT TEST.</h1>
        <h4 class="mb-4">
          Sulla base delle informazioni fornite, suggeriamo il seguente elenco
          di supplement:
        </h4>
        <template v-if="hasProducts">
          <b-row class="mt-4">
            <b-col
              v-if="beforeProducts && beforeProducts.length"
              cols="12"
              md="3"
              class="mb-4"
            >
              <h5>PRE:</h5>
              <div v-for="(product, i) in beforeProducts" :key="product.id">
                {{ product.name }}
                <span v-if="i < beforeProducts.length - 1">,</span>
              </div>
            </b-col>
            <b-col
              v-if="duringProducts && duringProducts.length"
              cols="12"
              md="3"
              class="mb-4"
            >
              <h5>DURANTE:</h5>
              <div v-for="(product, i) in duringProducts" :key="product.id">
                {{ product.name }}
                <span v-if="i < duringProducts.length - 1">,</span>
              </div>
            </b-col>
            <b-col
              v-if="afterProducts && afterProducts.length"
              cols="12"
              md="3"
              class="mb-4"
            >
              <h5>POST:</h5>
              <div v-for="(product, i) in afterProducts" :key="product.id">
                {{ product.name }}
                <span v-if="i < afterProducts.length - 1">,</span>
              </div>
            </b-col>
            <b-col
              v-if="freeTimeProducts && freeTimeProducts.length"
              cols="12"
              md="3"
              class="mb-4"
            >
              <h5>FREE TIME:</h5>
              <div v-for="(product, i) in freeTimeProducts" :key="product.id">
                {{ product.name }}
                <span v-if="i < freeTimeProducts.length - 1">,</span>
              </div>
            </b-col>
          </b-row>
          <div v-if="isDesktopApp" style="font-size: 1.15rem">
            TI INVITIAMO A SCOPRIRE NEL PUNTO VENDITA TUTTI I FORMATI ED I GUSTI
            DEI PRODOTTI CONSIGLIATI.
          </div>
          <a
            v-if="!isDesktopApp"
            href="https://www.namedsport.com/it/prodotti.html"
            style="font-size: 1.5rem"
            @click.stop=""
          >
            TI INVITIAMO A SCOPRIRE NEL NOSTRO SITO TUTTI I FORMATI ED I GUSTI
            DEI PRODOTTI CONSIGLIATI.
          </a>
          <div v-if="isDesktopApp" class="print-icon">
            <print-icon></print-icon>
            <div>stampa scontrino in corso...</div>
          </div>
        </template>
        <div class="my-3" style="font-size: 1.15rem">
          Consulta la posta elettronica per ulteriori spiegazioni ed
          approfondimenti <br />
          sulle referenze selezionate dallo Sport Test.
        </div>
        <div v-if="isDesktopApp" class="description">
          IN CASO DI INTOLLERANZE ED ALLERGIE GRAVI O SE ASSUMI FARMACI,<br />
          TI RICORDIAMO DI RIVOLGERTI AL TUO MEDICO.
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from '@/utils/axios';
import { mapGetters } from 'vuex';
import PrintIcon from '@/components/icons/PrintIcon.vue';
import getEmailContent from '../server/utils/getEmailContent';

export default {
  components: {
    PrintIcon,
  },
  data() {
    return {
      timer: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: 'wizard/user',
      showItem: 'wizard/showItem',
      printList: 'wizard/printList',
      printPre: 'wizard/printPre',
      printPost: 'wizard/printPost',
      beforeProducts: 'products/before',
      duringProducts: 'products/during',
      afterProducts: 'products/after',
      freeTimeProducts: 'products/freeTime',
      sportName: 'wizard/sportName',
      subSportName: 'wizard/subSportName',
      targetName: 'wizard/targetName',
    }),
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
    hasProducts() {
      return (
        (this.beforeProducts && this.beforeProducts.length) ||
        (this.duringProducts && this.duringProducts.length) ||
        (this.afterProducts && this.afterProducts.length) ||
        (this.freeTimeProducts && this.freeTimeProducts.length)
      );
    },
  },
  created() {
    this.timer = 0;
    this.timerInterval = setInterval(() => {
      this.incrementTimer();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  mounted() {
    if (this.user) {
      const emailData = {
        sport:
          this.subSportName && this.subSportName.length > 0
            ? `${this.sportName} - ${this.subSportName}`
            : this.sportName,
        goal: this.targetName,
        beforeProducts: this.beforeProducts
          ? this.beforeProducts.slice(0, 5).map((product) => {
              return {
                image: product.image.startsWith('https://')
                  ? product.image
                  : 'https://www.namedsport.com/it/pub/media/catalog/product' +
                    product.image,
                title: product.name,
                description: product.shortDescription,
              };
            })
          : null,
        duringProducts: this.duringProducts
          ? this.duringProducts.slice(0, 5).map((product) => {
              return {
                image: product.image.startsWith('https://')
                  ? product.image
                  : 'https://www.namedsport.com/it/pub/media/catalog/product' +
                    product.image,
                title: product.name,
                description: product.shortDescription,
              };
            })
          : null,
        afterProducts: this.afterProducts
          ? this.afterProducts.slice(0, 5).map((product) => {
              return {
                image: product.image.startsWith('https://')
                  ? product.image
                  : 'https://www.namedsport.com/it/pub/media/catalog/product' +
                    product.image,
                title: product.name,
                description: product.shortDescription,
              };
            })
          : null,
        freeTimeProducts: this.freeTimeProducts
          ? this.freeTimeProducts.slice(0, 5).map((product) => {
              return {
                image: product.image.startsWith('https://')
                  ? product.image
                  : 'https://www.namedsport.com/it/pub/media/catalog/product' +
                    product.image,
                title: product.name,
                description: product.shortDescription,
              };
            })
          : null,
      };

      if (process.env.VUE_APP_DESTINATION === 'app') {
        axios
          .post('/app/emails/send', {
            accessToken:
              'JA7Qg9m3JeMCUNTPVRSkQU4k4VV4zNGFp2WyTNsMNDCvNuvKwKdxSp5SQTb8VY2w',
            email: this.user.email,
            ...emailData,
          })
          .then((response) => {
            console.log('sendEmailResponse', response);
          })
          .catch((error) => {
            console.log('sendEmailError', error);
          });
      } else {
        axios
          .post('/app/emails/send', {
            accessToken:
              'JA7Qg9m3JeMCUNTPVRSkQU4k4VV4zNGFp2WyTNsMNDCvNuvKwKdxSp5SQTb8VY2w',
            to: this.user.email,
            subject: 'Questo è il tuo Sport Test NAMEDSPORT',
            body: getEmailContent(emailData),
          })
          .then((response) => {
            console.log('sendEmailResponse', response);
          })
          .catch((error) => {
            console.log('sendEmailError', error);
          });
      }
    }
    this.getConnectedPrinter();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters['wizard/sex']) {
        next();
      } else {
        next('/landing');
      }
    });
  },
  methods: {
    incrementTimer() {
      this.timer++;
    },
    goToStart() {
      if (this.timer > 10) {
        this.$router.push('/');
      }
    },
    getConnectedPrinter() {
      if (this.hasProducts) {
        const data = {
          accessToken:
            'JA7Qg9m3JeMCUNTPVRSkQU4k4VV4zNGFp2WyTNsMNDCvNuvKwKdxSp5SQTb8VY2w',
          sportName: this.sportName,
          subSportName: this.subSportName,
          targetName: this.targetName,
          printPre: this.printPre,
          beforeProducts: this.beforeProducts,
          duringProducts: this.duringProducts,
          afterProducts: this.afterProducts,
          freeTimeProducts: this.freeTimeProducts,
          printPost: this.printPost,
        };
        axios
          .post('/app/products/print', {
            data,
          })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
