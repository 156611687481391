<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="step-bar">
        <div class="d-flex align-items-center text-primary step-number">
          <small>STEP</small> <span class="">8/11</span>

          <a
            v-if="!isDesktopApp"
            href="https://www.namedsport.com"
            class="ml-auto"
          >
            <img src="@/assets/home.png" alt="" width="52" height="52" />
          </a>
        </div>
        <div class="step-title text-uppercase">
          QUANTO TI ALLENI DURANTE LA SETTIMANA?
        </div>
      </div>
      <div class="content">
        <div class="container container-small">
          <b-row>
            <b-col
              v-for="item in items"
              :key="item.id"
              cols="12"
              md="4"
              class="mb-4"
            >
              <b-card
                @click="selectFrequency(item.title)"
                class="card-md card-clickable"
                :class="item.title === frequency ? 'selected' : ''"
              >
                {{ item.title }}
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="left">
          <div class="prev-button" @click="$router.push('/target')">
            <span>INDIETRO</span>
          </div>
        </div>
        <div class="center">
          <timer v-if="isDesktopApp" />
        </div>
        <div class="right">
          <div
            class="next-button"
            :class="frequency ? 'active' : ''"
            @click="toNextStep"
          >
            <span>CONTINUA</span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Timer from '@/components/Timer';

export default {
  name: 'Frequency',
  components: {
    Timer,
  },
  data() {
    return {
      items: [
        { id: 1, title: '1/2 volte' },
        { id: 2, title: '3/4 volte' },
        { id: 3, title: 'Più di 4 volte' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      frequency: 'wizard/frequency',
    }),
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters['wizard/sex']) {
        next();
      } else {
        next('/landing');
      }
    });
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    ...mapActions({
      selectFrequency: 'wizard/selectFrequency',
    }),
    onKeyDown({ code }) {
      if (code === 'Enter') {
        this.toNextStep();
      }
    },
    toNextStep() {
      if (this.frequency) {
        this.$router.push('/duration');
      }
    },
  },
};
</script>
