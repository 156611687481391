<template>
  <div id="app" @click="resetIdle">
    <router-view />
  </div>
</template>

<script>
import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import VeeValidate from 'vee-validate';
import { mapMutations, mapActions } from 'vuex';
import VueMq from 'vue-mq';

import axios from '@/utils/axios';

Vue.use(BootstrapVue);
Vue.use(VeeValidate);
Vue.use(VueMq, {
  breakpoints: {
    sm: 768,
    lg: Infinity,
  },
});

export default {
  created() {
    this.resetIdle();

    if (
      process.env.NODE_ENV !== 'development' &&
      process.env.VUE_APP_DESTINATION === 'app'
    ) {
      setInterval(() => {
        this.updateIdle();
      }, 1000);
    }

    this.resetMain();
    setInterval(() => {
      this.incrementMain();
    }, 1000);

    if (process.env.VUE_APP_DESTINATION === 'app') {
      this.syncData();

      window.setInterval(() => {
        this.syncData();
      }, 1000 * 60 * 60 * 24);
    }
  },
  methods: {
    ...mapMutations({
      resetIdle: 'timer/resetIdle',
      resetMain: 'timer/resetMain',
      incrementMain: 'timer/incrementMain',
    }),
    ...mapActions({
      updateIdle: 'timer/updateIdle',
    }),
    async syncData() {
      try {
        await axios.post('/app/data/sync', {});
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
