<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div v-if="isDesktopApp" class="landing-page">
        <div class="landing-page__logo"></div>

        <div class="landing-page__variants">
          <div class="landing-page__test" @click="startTest">
            <div class="landing-page__test__image"></div>
          </div>

          <div class="landing-page__catalog" @click="startCatalog">
            <div class="landing-page__catalog__image"></div>
          </div>
        </div>
      </div>

      <div v-if="!isDesktopApp" class="web-landing-page" @click="startTest">
        <div class="web-landing-page__content">
          <img
            src="@/assets/landing-logo.jpg"
            alt=""
            width="266"
            height="78"
            class="web-landing-page__logo"
          />

          <div class="web-landing-page__first-row">INIZIA IL TUO</div>

          <div class="web-landing-page__second-row">SPORT TEST</div>

          <div class="web-landing-page__third-row">
            SCOPRI I PRODOTTI PIÙ ADATTI ALLE TUE PERFORMANCE
          </div>

          <div class="web-landing-page__button" :style="startButtonStyle">
            {{ startButton }}
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'landing-page',
  data() {
    return {
      productName: null,
      error: null,
    };
  },
  created() {
    // this.getSports()
    // this.getAims()
    // this.clearSelection()
  },
  components: {},
  computed: {
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
    startButton() {
      return ['TAP HERE', 'START'][+(this.$mq === 'lg')];
    },
    startButtonStyle() {
      // TODO: refactor. It does not look correct
      if (this.$mq !== 'lg') {
        return {
          'font-size': '3.2rem',
          bottom: '-32px',
        };
      }

      return {};
    },
  },
  methods: {
    ...mapActions({
      getSports: 'categories/getSports',
      getAims: 'categories/getAims',
      clearSelection: 'categories/clearSelection',
      getProducts: 'products/getProducts',
    }),
    ...mapMutations({
      clearState: 'wizard/clearState',
      resetMain: 'timer/resetMain',
      clearProduct: 'products/clearProduct',
    }),
    startTest() {
      this.clearState();
      this.getProducts()
        .then(() => {
          this.resetMain();
          this.$router.push('/information');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startCatalog() {
      this.clearState();
      this.clearProduct();
      this.getProducts()
        .then(() => {
          this.resetMain();
          this.$router.push('/catalog-target');
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
