<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="step-bar">
        <div class="d-flex align-items-center text-primary step-number">
          <small>STEP</small> <span class="">6/11</span>

          <a
            v-if="!isDesktopApp"
            href="https://www.namedsport.com"
            class="ml-auto"
          >
            <img src="@/assets/home.png" alt="" width="52" height="52" />
          </a>
        </div>
        <div class="step-title">CHE SPORT FAI?</div>
      </div>
      <div class="content">
        <div class="container container-small">
          <h2 class="text-center mb-4">{{ sportName }}</h2>
          <b-row>
            <b-col
              v-for="(item, i) in items[sportName]"
              :key="i"
              cols="6"
              :md="sportName === 'Sport di Squadra' ? 3 : 4"
              class="mb-4"
            >
              <b-card
                @click="selectSubSport(item)"
                class="card-sm card-clickable"
                :class="subSportName === item.name ? 'selected' : ''"
              >
                <img
                  :src="
                    require(`../assets/sports/${item.name
                      .replace(/ /g, '')
                      .toLowerCase()}.png`)
                  "
                  :alt="item.name"
                  class="sport-image"
                />
                <div class="sport-title">{{ item.name }}</div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="left">
          <div class="prev-button" @click="$router.push('/sport')">
            <span>INDIETRO</span>
          </div>
        </div>
        <div class="center">
          <timer v-if="isDesktopApp" />
        </div>
        <div class="right">
          <div
            class="next-button"
            :class="subSportName ? 'active' : ''"
            @click="toNextStep"
          >
            <span>CONTINUA</span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Timer from '@/components/Timer';

export default {
  name: 'sub-sports-page',
  components: {
    Timer,
  },
  data() {
    return {
      items: {
        Ciclismo: [
          { name: 'Allenamento corto', id: 7 },
          { name: 'Allenamento medio', id: 7 },
          { name: 'Allenamento lungo', id: 7 },
        ],
        Running: [
          { name: '10 km', id: 9 },
          { name: 'Mezza maratona', id: 9 },
          { name: 'Maratona', id: 9 },
        ],
        'Sport di Squadra': [
          { name: 'Calcio', id: 8 },
          { name: 'Pallacanestro', id: 8 },
          { name: 'Pallanuoto', id: 85 },
          { name: 'Pallavolo', id: 8 },
          { name: 'Rugby', id: 83 },
          { name: 'Pallamano', id: 8 },
          { name: 'Hockey', id: 8 },
          { name: 'Bob', id: 10 },
        ],
        'Altri Sport': [
          { name: 'Arrampicata', id: 10 },
          { name: 'Equitazione', id: 8 },
          { name: 'Canottaggio', id: 85 },
          { name: 'Arti marziali', id: 10 },
          { name: 'Scherma', id: 10 },
          { name: 'Pattinaggio', id: 8 },
          { name: 'Golf', id: 31 },
          { name: 'Atletica leggera', id: 9 },
          { name: 'Ginnastica Artistica', id: 10 },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      sport: 'wizard/sport',
      sportName: 'wizard/sportName',
      subSportName: 'wizard/subSportName',
    }),
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters['wizard/sex']) {
        next();
      } else {
        next('/landing');
      }
    });
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    ...mapActions({
      selectSubSport: 'wizard/selectSubSport',
    }),
    onKeyDown({ code }) {
      if (code === 'Enter') {
        this.toNextStep();
      }
    },
    toNextStep() {
      if (this.subSportName) {
        this.$router.push('/target');
      }
    },
  },
};
</script>
