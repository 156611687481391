<template>
  <div id="wrapper">
    <main class="video-page">
      <div @click="$router.push('/landing')" class="video">
        <video autoplay playsinline muted loop width="auto" height="100%">
          <source src="landing.mp4" type="video/mp4" />
        </video>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'video-page',
  beforeRouteEnter(to, from, next) {
    if (process.env.VUE_APP_DESTINATION === 'site') {
      next('/landing');
    }

    next();
  },
};
</script>

<style lang="scss">
.video {
  height: 100vh;
  overflow: hidden;
}
</style>
