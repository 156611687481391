const state = {
  sex: null,
  height: null,
  weight: null,
  diet: null,
  hasIntolerance: null,
  intolerance: null,
  hasAllergy: null,
  sport: null,
  sportName: null,
  subSportName: null,
  target: null,
  targetName: null,
  frequency: null,
  duration: null,
  age: null,
  user: null,
  printPre: [
    'Gentile Cliente',
    'grazie per aver completato',
    'lo Sport Test',
    'NAMEDSPORT>.',
    ' ',
    'Sulla base delle informazioni',
    'che ci hai fornito e ',
    'considerando le Tue ',
    'caratteristiche personali, ',
    'lo sport da Te praticato, ',
    'il numero medio e',
    'la durata dei Tuoi allenamenti,',
    'potrai trovare di seguito la',
    "lista dei prodotti che piu' si",
    'addicono alle Tue esigenze:',
    ' ',
  ],
  printPost: [
    'Ti invitiamo a scoprire nel',
    'punto vendita tutti i ',
    'formati ed i gusti dei ',
    'prodotti consigliati.',
    'Per rimanere aggiornato',
    'e ricevere suggerimenti',
    'su alimentazione ed',
    'allenamento, visita il blog:',
    'www.stay-trained.com.',
    ' ',
  ],
};

const getters = {
  sex: (state) => state.sex,
  height: (state) => state.height,
  weight: (state) => state.weight,
  diet: (state) => state.diet,
  hasIntolerance: (state) => state.hasIntolerance,
  intolerance: (state) => state.intolerance,
  hasAllergy: (state) => state.hasAllergy,
  sport: (state) => state.sport,
  sportName: (state) => state.sportName,
  subSportName: (state) => state.subSportName,
  target: (state) => state.target,
  targetName: (state) => state.targetName,
  frequency: (state) => state.frequency,
  duration: (state) => state.duration,
  age: (state) => state.age,
  user: (state) => state.user,
  printPre: (state) => {
    return state.printPre.map((row) => {
      return row.replace('Cliente', state.user.name);
    });
  },
  printPost: (state) => state.printPost,
};

const mutations = {
  selectSex(state, sex) {
    state.sex = sex;
  },
  setHeight(state, height) {
    state.height = height;
  },
  setWeight(state, weight) {
    state.weight = weight;
  },
  selectDiet(state, diet) {
    state.diet = diet;
  },
  selectHasIntolerance(state, hasIntolerance) {
    state.hasIntolerance = hasIntolerance;
  },
  selectIntolerance(state, intolerance) {
    state.intolerance = intolerance;
  },
  selectHasAllergy(state, hasAllergy) {
    state.hasAllergy = hasAllergy;
  },
  selectSport(state, sport) {
    state.sport = sport.id;
    state.sportName = sport.name;
    state.subSportName = null;
  },
  selectSubSport(state, subSport) {
    state.sport = subSport.id;
    state.subSportName = subSport.name;
  },
  selectTarget(state, target) {
    state.target = target.value;
    state.targetName = target.label;
  },
  selectFrequency(state, frequency) {
    state.frequency = frequency;
  },
  selectDuration(state, duration) {
    state.duration = duration;
  },
  selectAge(state, age) {
    state.age = age;
  },
  setUser(state, user) {
    state.user = user;
  },
  clearState(state) {
    state.sex = null;
    state.height = null;
    state.weight = null;
    state.diet = null;
    state.hasIntolerance = null;
    state.intolerance = null;
    state.hasAllergy = null;
    state.sport = null;
    state.sportName = null;
    state.subSportName = null;
    state.target = null;
    state.frequency = null;
    state.duration = null;
    state.age = null;
    state.user = null;
  },
};

const actions = {
  selectDiet({ commit, dispatch }, diet) {
    commit('selectDiet', diet.value);
    dispatch(
      'analytics/trackEvent',
      { ec: 'customer', ea: 'alimentazione', el: 'tipo', ev: diet.label },
      { root: true }
    );
  },
  selectHasIntolerance({ commit, dispatch }, hasIntolerance) {
    commit('selectHasIntolerance', hasIntolerance);
    dispatch(
      'analytics/trackEvent',
      {
        ec: 'customer',
        ea: 'alimentazione',
        el: 'intollerante',
        ev: hasIntolerance ? 'SI' : 'NO',
      },
      { root: true }
    );
  },
  selectIntolerance({ commit, dispatch }, intolerance) {
    commit('selectIntolerance', intolerance);
    dispatch(
      'analytics/trackEvent',
      {
        ec: 'customer',
        ea: 'alimentazione',
        el: 'intolleranza',
        ev: intolerance,
      },
      { root: true }
    );
  },
  selectHasAllergy({ commit, dispatch }, hasAllergy) {
    commit('selectHasAllergy', hasAllergy);
    dispatch(
      'analytics/trackEvent',
      {
        ec: 'customer',
        ea: 'alimentazione',
        el: 'allergico',
        ev: hasAllergy ? 'SI' : 'NO',
      },
      { root: true }
    );
  },
  selectSport({ commit, dispatch }, sport) {
    commit('selectSport', sport);
    dispatch(
      'analytics/trackEvent',
      { ec: 'customer', ea: 'sport', el: 'tipo', ev: sport.name },
      { root: true }
    );
  },
  selectSubSport({ commit, dispatch }, subSport) {
    commit('selectSubSport', subSport);
    dispatch(
      'analytics/trackEvent',
      { ec: 'customer', ea: 'sport', el: 'tipo', ev: subSport.name },
      { root: true }
    );
  },
  selectTarget({ commit, dispatch }, target) {
    commit('selectTarget', target);
    dispatch(
      'analytics/trackEvent',
      { ec: 'customer', ea: 'sport', el: 'obiettivo', ev: target.label },
      { root: true }
    );
  },
  selectFrequency({ commit, dispatch }, frequency) {
    commit('selectFrequency', frequency);
    dispatch(
      'analytics/trackEvent',
      { ec: 'customer', ea: 'sport', el: 'allenamenti', ev: frequency },
      { root: true }
    );
  },
  selectDuration({ commit, dispatch }, duration) {
    commit('selectDuration', duration);
    dispatch(
      'analytics/trackEvent',
      { ec: 'customer', ea: 'sport', el: 'durata', ev: duration },
      { root: true }
    );
  },
  selectAge({ commit, dispatch }, age) {
    commit('selectAge', age);
    dispatch(
      'analytics/trackEvent',
      { ec: 'customer', ea: 'profilo', el: 'eta', ev: age },
      { root: true }
    );
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
