<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="header">
        <div @click="$router.go(-1)" class="back">< Back</div>
        <div class="title">Wishlist</div>
        <div class="wishlist"></div>
      </div>
      <div class="content">
        <div class="grid">
          <div
            v-for="(product, index) in wishList"
            :key="index"
            class="grid-item grid-item-product"
          >
            <span>{{ product.name || product.sku }}</span>
            <div @click="removeFromWishList(index)" class="button button-white">
              remove from wishlist
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <b-button block v-b-modal.printModal>STAMPA WISHLIST</b-button>
      </div>

      <!-- Print Modal -->
      <b-modal
        id="printModal"
        size="xl"
        title="Stampa Wishlist"
        :hide-footer="true"
        no-stacking
      >
        <b-form @submit="onSubmit" v-if="show">
          <b-form-group
            id="emailInputGroup"
            label="Email address:"
            label-for="emailInput"
          >
            <b-form-input
              id="emailInput"
              type="email"
              v-model="form.email"
              required
              placeholder="Enter email"
            />
          </b-form-group>

          <b-form-group id="exampleGroup4">
            <b-form-checkbox-group v-model="form.checked" id="exampleChecks">
              <b-form-checkbox value="newsletter1">Newsletter 1</b-form-checkbox
              ><br />
              <b-form-checkbox value="newsletter2">Newsletter 2</b-form-checkbox
              ><br />
              <b-form-checkbox value="newsletter3">Newsletter 3</b-form-checkbox
              ><br /><br />
              <b-form-checkbox value="privacy">Privacy policy</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <b-button block type="submit" variant="primary"
            >CONFERMA E STAMPA WISHLIST</b-button
          >
        </b-form>
      </b-modal>

      <!-- List Modal -->
      <b-modal
        id="listModal"
        ref="listModalRef"
        size="xl"
        title="Your Wishlist"
        :hide-footer="true"
      >
        <b-container fluid>
          <ul>
            <li v-for="(product, index) in wishList" :key="index">
              {{ product.name }}
            </li>
          </ul>
        </b-container>
      </b-modal>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'wishlist-page',
  data() {
    return {
      form: {
        email: '',
        checked: [],
      },
      show: true,
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      wishList: 'products/wishList',
    }),
  },
  methods: {
    ...mapMutations({
      removeFromWishList: 'products/removeFromWishList',
    }),
    ...mapActions({}),
    onSubmit(evt) {
      evt.preventDefault();
      this.$refs.listModalRef.show();
      // alert('Wishlist: '+JSON.stringify(this.wishList)+'\n\nForm: '+JSON.stringify(this.form))
    },
  },
};
</script>
