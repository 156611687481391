<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <!--      <div class="step-bar">-->
      <!--<div class="text-primary step-number">-->
      <!--<small>STEP</small> <span class="">7/11</span>-->
      <!--</div>-->
      <!--        <div class="step-title text-uppercase">QUAL È IL TUO OBIETTIVO?</div>-->
      <!--      </div>-->
      <div class="content" style="height: calc(100vh - 70px)">
        <div class="container">
          <b-row v-if="product" class="align-items-center">
            <b-col cols="12" md="6">
              <img :src="product.imageFileUrl" alt="" class="w-100" />
            </b-col>
            <b-col cols="12" md="6">
              <h1 class="text-left mb-4">{{ product.name }}</h1>
              <div class="font-secondary" v-html="product.description"></div>
              <template v-if="product.sport">
                <h3 class="text-left mt-4">Sport</h3>
                <div class="font-secondary">
                  <span v-for="(sport, i) in product.sport" :key="i">
                    {{ sports.find((item) => item.value === sport).label
                    }}<span v-if="i < product.sport.length - 1">, </span>
                  </span>
                </div>
              </template>
              <template v-if="product.comeHow">
                <h3 class="text-left mt-4">Come</h3>
                <div class="font-secondary" v-html="product.comeHow"></div>
              </template>
              <template v-if="product.quandoWhen">
                <h3 class="text-left mt-4">Quando</h3>
                <div class="font-secondary" v-html="product.quandoWhen"></div>
              </template>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="left">
          <div class="prev-button" @click="$router.push('/catalog')">
            <span>INDIETRO</span>
          </div>
        </div>
        <div class="center">
          <timer v-if="isDesktopApp" />
        </div>
        <div class="right">
          <div class="next-button active" @click="$router.push('/landing')">
            <span>FINIRE</span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Timer from '@/components/Timer';

export default {
  name: 'CatalogProduct',
  components: {
    Timer,
  },
  computed: {
    ...mapGetters({
      product: 'products/selectedProduct',
      sports: 'categories/sports',
    }),
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters['wizard/target']) {
        next();
      } else {
        next('/landing');
      }
    });
  },
};
</script>
