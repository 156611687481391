<template>
  <div id="wrapper">
    <div
      style="position: absolute; top: 0; right: 0; width: 50px; height: 50px"
      @dblclick="$ipc('disableFullscreen')"
    ></div>
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="step-bar">
        <div class="d-flex align-items-center text-primary step-number">
          <small>STEP</small> <span class="">1/11</span>

          <a
            v-if="!isDesktopApp"
            href="https://www.namedsport.com"
            class="ml-auto"
          >
            <img src="@/assets/home.png" alt="" width="52" height="52" />
          </a>
        </div>
        <div class="step-title">QUALCHE INFORMAZIONE SU DI TE...</div>
      </div>
      <div class="content">
        <div class="container">
          <b-row>
            <b-col cols="12" md="4" class="mb-4">
              <b-card class="card-lg">
                <div class="card-lg-title">SESSO</div>
                <div class="w-100 buttons-group">
                  <button
                    type="button"
                    class="btn btn-block btn-rounded mb-4"
                    @click="selectSex('Maschio')"
                    :class="
                      sex === 'Maschio'
                        ? 'btn-outline-primary'
                        : 'btn-outline-dark'
                    "
                  >
                    Maschio
                  </button>
                  <button
                    type="button"
                    class="btn btn-block btn-rounded mb-4"
                    @click="selectSex('Femmina')"
                    :class="
                      sex === 'Femmina'
                        ? 'btn-outline-primary'
                        : 'btn-outline-dark'
                    "
                  >
                    Femmina
                  </button>
                </div>
              </b-card>
            </b-col>

            <b-col cols="12" md="4" class="mb-4">
              <b-card class="card-lg">
                <div class="card-lg-title">LA TUA ALTEZZA</div>
                <div class="text-primary" :style="!height ? 'opacity: 0' : ''">
                  {{ height }} cm
                </div>
                <div class="w-100 d-flex align-items-center">
                  <minus-icon
                    class="mr-3"
                    :class="height <= minHeight ? 'disabled' : ''"
                    @click.native="decreaseHeight"
                  />
                  <div class="flex-grow-1">
                    <vue-slider
                      v-model="height"
                      :tooltip="'none'"
                      :dot-size="24"
                      :height="6"
                      :min="minHeight"
                      :max="maxHeight"
                      :contained="true"
                      @change="changeHeight"
                    />
                  </div>
                  <plus-icon
                    class="ml-3"
                    :class="height >= maxHeight ? 'disabled' : ''"
                    @click.native="increaseHeight"
                  />
                </div>

                <div class="slider-desc" :style="height ? 'opacity: 0' : ''">
                  Trascina il cursore, oppure utilizza i pulsanti + e -, per
                  impostare l’altezza desiderata
                </div>
              </b-card>
            </b-col>

            <b-col cols="12" md="4" class="mb-4">
              <b-card class="card-lg">
                <div class="card-lg-title">IL TUO PESO</div>
                <div class="text-primary" :style="!weight ? 'opacity: 0' : ''">
                  {{ weight }} kg
                </div>
                <div class="w-100 d-flex align-items-center">
                  <minus-icon
                    class="mr-3"
                    :class="weight <= minWeight ? 'disabled' : ''"
                    @click.native="decreaseWeight"
                  />
                  <div class="flex-grow-1">
                    <vue-slider
                      v-model="weight"
                      :tooltip="'none'"
                      :dot-size="24"
                      :height="6"
                      :min="minWeight"
                      :max="maxWeight"
                      :contained="true"
                      @change="changeWeight"
                    />
                  </div>
                  <plus-icon
                    class="ml-3"
                    :class="weight >= maxWeight ? 'disabled' : ''"
                    @click.native="increaseWeight"
                  />
                </div>
                <div class="slider-desc" :style="weight ? 'opacity: 0' : ''">
                  Trascina il cursore, oppure utilizza i pulsanti + e -, per
                  impostare il peso desiderato
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="left"></div>
        <div class="center">
          <timer v-if="isDesktopApp" />
        </div>
        <div class="right">
          <div
            class="next-button"
            :class="this.sex && this.height && this.weight ? 'active' : ''"
            @click="toNextStep"
          >
            <span>CONTINUA</span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import Timer from '@/components/Timer';
import MinusIcon from '@/components/icons/MinusIcon';
import PlusIcon from '@/components/icons/PlusIcon';

export default {
  components: {
    VueSlider,
    Timer,
    MinusIcon,
    PlusIcon,
  },
  data() {
    return {
      minHeight: 100,
      maxHeight: 220,
      minWeight: 30,
      maxWeight: 130,
    };
  },
  computed: {
    ...mapGetters({
      sex: 'wizard/sex',
      height: 'wizard/height',
      weight: 'wizard/weight',
    }),
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    ...mapMutations({
      selectSex: 'wizard/selectSex',
      setHeight: 'wizard/setHeight',
      setWeight: 'wizard/setWeight',
    }),
    ...mapActions({
      trackEvent: 'analytics/trackEvent',
    }),
    onKeyDown({ code }) {
      if (code === 'Enter') {
        this.toNextStep();
      }
    },
    changeHeight(value) {
      this.setHeight(value);
    },
    changeWeight(value) {
      this.setWeight(value);
    },
    increaseHeight() {
      if (this.height < this.maxHeight) {
        if (this.height === null) {
          this.setHeight(this.minHeight);
        } else {
          this.setHeight(this.height + 1);
        }
      }
    },
    decreaseHeight() {
      if (this.height > this.minHeight) {
        this.setHeight(this.height - 1);
      }
    },
    increaseWeight() {
      if (this.weight < this.maxWeight) {
        if (this.weight === null) {
          this.setWeight(this.minWeight);
        } else {
          this.setWeight(this.weight + 1);
        }
      }
    },
    decreaseWeight() {
      if (this.weight > this.minWeight) {
        this.setWeight(this.weight - 1);
      }
    },
    toNextStep() {
      if (this.sex && this.height && this.weight) {
        this.trackEvent({
          ec: 'customer',
          ea: 'profile',
          el: 'sesso',
          ev: this.sex,
        });
        this.trackEvent({
          ec: 'customer',
          ea: 'profile',
          el: 'altezza',
          ev: this.height,
        });
        this.trackEvent({
          ec: 'customer',
          ea: 'profile',
          el: 'peso',
          ev: this.weight,
        });
        this.$router.push('/diet');
      }
    },
  },
};
</script>
