<template>
  <div>{{ mainTimer }}</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      mainTimer: 'timer/mainTimer',
    }),
  },
};
</script>
