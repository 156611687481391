<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="step-bar">
        <div class="d-flex align-items-center text-primary step-number">
          <small>STEP</small> <span class="">7/11</span>

          <a
            v-if="!isDesktopApp"
            href="https://www.namedsport.com"
            class="ml-auto"
          >
            <img src="@/assets/home.png" alt="" width="52" height="52" />
          </a>
        </div>
        <div class="step-title text-uppercase">QUAL È IL TUO OBIETTIVO?</div>
      </div>
      <div class="content">
        <div class="container container-small">
          <b-row class="justify-content-center">
            <b-col
              v-for="target in activeTargets"
              :key="target.value"
              cols="6"
              md="4"
              class="mb-4"
            >
              <b-card
                @click="selectTarget(target)"
                class="card-sm card-clickable"
                :class="selectedTarget === target.value ? 'selected' : ''"
              >
                <img
                  :src="
                    require(`../assets/targets/${target.label
                      .split(' ')
                      .join('-')
                      .toLowerCase()}.png`)
                  "
                  alt=""
                  class="sport-image"
                />
                <div class="sport-title">{{ target.label }}</div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="left">
          <div class="prev-button" @click="$router.push('/sport')">
            <span>INDIETRO</span>
          </div>
        </div>
        <div class="center">
          <timer v-if="isDesktopApp" />
        </div>
        <div class="right">
          <div
            class="next-button"
            :class="selectedTarget ? 'active' : ''"
            @click="toNextStep"
          >
            <span>CONTINUA</span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Timer from '@/components/Timer';

export default {
  name: 'Target',
  components: {
    Timer,
  },
  computed: {
    ...mapGetters({
      selectedTarget: 'wizard/target',
      activeTargets: 'products/activeTargets',
    }),
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
  },
  created() {
    this.getTargets();
    this.getActiveTargetIds().then((targetIds) => {
      console.log('targetIds based on selected sport: ');
      console.log(targetIds);
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters['wizard/sex']) {
        next();
      } else {
        next('/landing');
      }
    });
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    ...mapActions({
      getTargets: 'products/getTargets',
      getActiveTargetIds: 'products/getActiveTargetIds',
      selectTarget: 'wizard/selectTarget',
    }),
    onKeyDown({ code }) {
      if (code === 'Enter') {
        this.toNextStep();
      }
    },
    toNextStep() {
      if (this.selectedTarget) {
        this.$router.push('/frequency');
      }
    },
  },
};
</script>
