<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="step-bar">
        <!--<div class="text-primary step-number">-->
        <!--<small>STEP</small> <span class="">7/11</span>-->
        <!--</div>-->
        <div class="step-title text-uppercase">QUAL È IL TUO OBIETTIVO?</div>
      </div>
      <div class="content">
        <div class="container container-small">
          <b-row class="justify-content-center">
            <b-col
              v-for="target in targets"
              :key="target.value"
              cols="6"
              md="4"
              class="mb-4"
            >
              <b-card
                @click="onTargetClick(target)"
                class="card-sm card-clickable"
                :class="selectedTarget === target.value ? 'selected' : ''"
              >
                <img
                  :src="
                    require(`../assets/targets/${target.label
                      .split(' ')
                      .join('-')
                      .toLowerCase()}.png`)
                  "
                  alt=""
                  class="sport-image"
                />
                <div class="sport-title">{{ target.label }}</div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="left">
          <div class="prev-button" @click="$router.push('/landing')">
            <span>INDIETRO</span>
          </div>
        </div>
        <div class="center">
          <timer v-if="isDesktopApp" />
        </div>
        <div class="right"></div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Timer from '@/components/Timer';

export default {
  name: 'Target',
  components: {
    Timer,
  },
  computed: {
    ...mapGetters({
      selectedTarget: 'wizard/target',
      targets: 'products/targets',
    }),
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
  },
  created() {
    this.getTargets();
  },
  methods: {
    ...mapMutations({
      selectTarget: 'wizard/selectTarget',
    }),
    ...mapActions({
      getTargets: 'products/getTargets',
    }),
    onTargetClick(target) {
      this.selectTarget(target);
      if (this.selectedTarget) {
        this.$router.push('/catalog');
      }
    },
  },
};
</script>
