import axios from '@/utils/axios';

const state = {
  sports: null,
  aims: [
    {
      name: 'Dieta',
      id: 26,
      isSelected: false,
    },
    {
      name: 'Resistenza',
      id: 29,
      isSelected: false,
    },
    {
      name: 'Energia e Concentrazione',
      id: 25,
      isSelected: false,
    },
    {
      name: 'Sviluppo Massa Muscolare',
      id: 24,
      isSelected: false,
    },
    {
      name: 'Benessere',
      id: 22,
      isSelected: false,
    },
    {
      name: 'Definizione',
      id: 23,
      isSelected: false,
    },
    {
      name: 'Idratazione',
      id: 27,
      isSelected: false,
    },
    {
      name: 'Nutrizione',
      id: 28,
      isSelected: false,
    },
    {
      name: 'Recupero',
      id: 30,
      isSelected: false,
    },
  ],
};

const getters = {
  sports: (state) => state.sports,
  aims: (state) => state.aims,
  selectedSports: (state) => {
    return state.sports.filter((item) => {
      return item.isSelected === true;
    });
  },
  selectedSportIds: (state, getters) => {
    return getters.selectedSports.map((item) => {
      return item.id;
    });
  },
  selectedAims: (state) => {
    return state.aims.filter((item) => {
      return item.isSelected === true;
    });
  },
  selectedAimIds: (state, getters) => {
    return getters.selectedAims.map((item) => {
      return item.id;
    });
  },
};

const mutations = {
  receiveSports(state, sports) {
    sports.forEach((item) => {
      item.isSelected = false;
    });
    state.sports = sports;
  },
  receiveAims(state, aims) {
    aims.forEach((item) => {
      item.isSelected = false;
    });
    state.aims = aims;
  },
  toggleSport(state, index) {
    state.sports[index].isSelected = !state.sports[index].isSelected;
  },
  toggleAim(state, index) {
    state.aims[index].isSelected = !state.aims[index].isSelected;
  },
  clearSelection(state) {
    state.sports.forEach((item) => {
      item.isSelected = false;
    });
    state.aims.forEach((item) => {
      item.isSelected = false;
    });
  },
};

const actions = {
  getSports({ commit }) {
    return axios
      .post('/app/sports/list', {
        accessToken:
          'JA7Qg9m3JeMCUNTPVRSkQU4k4VV4zNGFp2WyTNsMNDCvNuvKwKdxSp5SQTb8VY2w',
      })
      .then(({ data }) => {
        commit('receiveSports', data.results);
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },
  getAims({ commit }) {
    return axios
      .post('/app/targets/list', {
        accessToken:
          'JA7Qg9m3JeMCUNTPVRSkQU4k4VV4zNGFp2WyTNsMNDCvNuvKwKdxSp5SQTb8VY2w',
      })
      .then(({ data }) => {
        commit('receiveAims', data.results);
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
