import Vue from 'vue';
import VueRouter from 'vue-router';

import Sport from '@/pages/Sport';
import SubSport from '@/pages/SubSport';
import VideoPage from '@/pages/VideoPage';
import LandingPage from '@/pages/LandingPage';
import Information from '@/pages/Information';
import Diet from '@/pages/Diet';
import IntoleranceDialog from '@/pages/IntoleranceDialog';
import Intolerance from '@/pages/Intolerance';
import AllergyDialog from '@/pages/AllergyDialog';
import Allergy from '@/pages/Allergy';
import Target from '@/pages/Target';
import Frequency from '@/pages/Frequency';
import Duration from '@/pages/Duration';
import Age from '@/pages/Age';
import Form from '@/pages/Form';
import PrintPage from '@/pages/PrintPage';
import CatalogTarget from '@/pages/CatalogTarget';
import Catalog from '@/pages/Catalog';
import CatalogProduct from '@/pages/CatalogProduct';
import ProfilePage from '@/pages/Profile';
import ProductsPage from '@/pages/Products';
import WishlistPage from '@/pages/Wishlist';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'video-page',
    component: VideoPage,
  },
  {
    path: '/landing',
    name: 'landing-page',
    component: LandingPage,
  },
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/sport',
    name: 'Sport',
    component: Sport,
  },
  {
    path: '/sub-sport',
    name: 'sub-sport',
    component: SubSport,
  },
  {
    path: '/profile',
    name: 'profile-page',
    component: ProfilePage,
  },
  {
    path: '/products',
    name: 'products-page',
    component: ProductsPage,
  },
  {
    path: '/wishlist',
    name: 'wishlist-page',
    component: WishlistPage,
  },
  {
    path: '/information',
    name: 'Information',
    component: Information,
  },
  {
    path: '/diet',
    name: 'Diet',
    component: Diet,
  },
  {
    path: '/intolerance',
    name: 'Intolerance',
    component: Intolerance,
  },
  {
    path: '/target',
    name: 'Target',
    component: Target,
  },
  {
    path: '/frequency',
    name: 'Frequency',
    component: Frequency,
  },
  {
    path: '/duration',
    name: 'Duration',
    component: Duration,
  },
  {
    path: '/age',
    name: 'age',
    component: Age,
  },
  {
    path: '/form',
    name: 'form',
    component: Form,
  },
  {
    path: '/print',
    name: 'print',
    component: PrintPage,
  },
  {
    path: '/intolerance-dialog',
    name: 'IntoleranceDialog',
    component: IntoleranceDialog,
  },
  {
    path: '/allergy',
    name: 'Allergy',
    component: Allergy,
  },
  {
    path: '/allergy-dialog',
    name: 'AllergyDialog',
    component: AllergyDialog,
  },
  {
    path: '/catalog-target',
    name: 'CatalogTarget',
    component: CatalogTarget,
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: Catalog,
  },
  {
    path: '/catalog-product',
    name: 'CatalogProduct',
    component: CatalogProduct,
  },
];

const router = new VueRouter({
  mode: process.env.VUE_APP_DESTINATION === 'site' ? 'history' : 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
