import axios from '@/utils/axios';

const state = {
  products: null,
  selectedProducts: null,
  productsToPrint: null,
  selectedProduct: null,
  wishList: [],
  activeTargetIds: null,
  targets: null,
};

const getters = {
  products: (state) => state.products,
  selectedProducts: (state) => state.products,
  productsToPrint: (state) => state.productsToPrint,
  productsByTarget: (state, getters, rootState) => {
    if (state.products) {
      return state.products.filter((product) => {
        return (
          product.obiettivi &&
          product.obiettivi.includes(rootState.wizard.target)
        );
      });
    }
  },
  selectedProduct: (state) => state.selectedProduct,
  wishList: (state) => state.wishList,
  targets: (state) => state.targets,
  activeTargetIds: (state) => state.activeTargetIds,
  activeTargets: (state) => {
    if (state.targets && state.activeTargetIds) {
      return state.targets.filter((target) =>
        state.activeTargetIds.includes(target.value)
      );
    }
    return null;
  },
  before: (state) => {
    if (state.productsToPrint) {
      const productsWithoutPriority = state.productsToPrint.filter(
        (product) => product.timing && product.timing.includes(32)
      );
      const products = productsWithoutPriority.map((product) => {
        product.priority = product.prioritaPre ? product.prioritaPre : 0;
        return product;
      });
      products.sort((a, b) => b.priority - a.priority);
      if (products.length > 5) {
        return products.slice(0, 5);
      } else {
        return products;
      }
    }
    return null;
  },
  during: (state) => {
    if (state.productsToPrint) {
      const productsWithoutPriority = state.productsToPrint.filter(
        (product) => product.timing && product.timing.includes(33)
      );
      const products = productsWithoutPriority.map((product) => {
        product.priority = product.prioritaDurante
          ? product.prioritaDurante
          : 0;
        return product;
      });
      products.sort((a, b) => b.priority - a.priority);
      if (products.length > 5) {
        return products.slice(0, 5);
      } else {
        return products;
      }
    }
    return null;
  },
  after: (state) => {
    if (state.productsToPrint) {
      const productsWithoutPriority = state.productsToPrint.filter(
        (product) => product.timing && product.timing.includes(34)
      );
      const products = productsWithoutPriority.map((product) => {
        product.priority = product.prioritaPost ? product.prioritaPost : 0;
        return product;
      });
      products.sort((a, b) => b.priority - a.priority);
      if (products.length > 5) {
        return products.slice(0, 5);
      } else {
        return products;
      }
    }
    return null;
  },
  freeTime: (state) => {
    if (state.productsToPrint) {
      const productsWithoutPriority = state.productsToPrint.filter(
        (product) => product.timing && product.timing.includes(283)
      );
      const products = productsWithoutPriority.map((product) => {
        product.priority = product.prioritaFree ? product.prioritaFree : 0;
        return product;
      });
      products.sort((a, b) => b.priority - a.priority);
      if (products.length > 5) {
        return products.slice(0, 5);
      } else {
        return products;
      }
    }
    return null;
  },
};

const mutations = {
  pushToWishList(state, product) {
    state.wishList.push(product);
  },
  selectProduct(state, product) {
    state.selectedProduct = product;
  },
  clearProduct(state) {
    state.selectedProduct = null;
  },
  removeFromWishList(state, productIndex) {
    state.wishList.splice(productIndex, 1);
  },
  receiveProducts(state, products) {
    state.products = products;
    console.log('Products received to state: ' + products.length);
  },
  receiveSelectedProducts(state, products) {
    state.selectedProducts = products;
    console.log('Products selected: ' + products.length);
  },
  receiveProductsToPrint(state, products) {
    state.productsToPrint = products;
    console.log('Products selected for printing:');
    console.log(products);
  },
  receiveTargets(state, targets) {
    state.targets = targets;
  },
  receiveActiveTargetIds(state, targetIds) {
    state.activeTargetIds = targetIds;
  },
};

const actions = {
  getProducts({ commit }) {
    return axios
      .post('/app/products/list', {
        accessToken:
          'JA7Qg9m3JeMCUNTPVRSkQU4k4VV4zNGFp2WyTNsMNDCvNuvKwKdxSp5SQTb8VY2w',
      })
      .then(({ data }) => {
        commit('receiveProducts', data.results);
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },
  getTargets({ commit }) {
    return axios
      .post('/app/targets/list', {
        accessToken:
          'JA7Qg9m3JeMCUNTPVRSkQU4k4VV4zNGFp2WyTNsMNDCvNuvKwKdxSp5SQTb8VY2w',
      })
      .then(({ data }) => {
        commit('receiveTargets', data.results);
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },
  getActiveTargetIds({ commit, rootState }) {
    return axios
      .post('/app/targets/matrix', {
        accessToken:
          'JA7Qg9m3JeMCUNTPVRSkQU4k4VV4zNGFp2WyTNsMNDCvNuvKwKdxSp5SQTb8VY2w',
      })
      .then(({ data }) => {
        const matrixItem = data.matrix.find(
          (item) => item.id === rootState.wizard.sport
        );
        if (matrixItem) {
          commit('receiveActiveTargetIds', matrixItem.obiettivi);
        }
        return matrixItem;
      })
      .catch((error) => {
        throw error;
      });
  },
  selectProductsToPrint({ commit, rootState }) {
    return axios
      .post('/app/products/list', {
        accessToken:
          'JA7Qg9m3JeMCUNTPVRSkQU4k4VV4zNGFp2WyTNsMNDCvNuvKwKdxSp5SQTb8VY2w',
      })
      .then(({ data }) => {
        console.log('selected diet: ' + rootState.wizard.diet);
        console.log('has intolerance: ' + rootState.wizard.hasIntolerance);
        if (rootState.wizard.hasIntolerance) {
          console.log('selected intolerance: ' + rootState.wizard.intolerance);
        }
        console.log('selected sport: ' + rootState.wizard.sport);
        console.log('selected target: ' + rootState.wizard.target);

        const productsToPrint = data.results.filter((product) => {
          const dietFilter =
            product.alimentazioneSeguita &&
            product.alimentazioneSeguita.includes(rootState.wizard.diet);
          const sportFilter =
            product.sport && product.sport.includes(rootState.wizard.sport);
          const targetFilter =
            product.obiettivi &&
            product.obiettivi.includes(rootState.wizard.target);
          let intoleranceFilter = true;
          if (rootState.wizard.hasIntolerance) {
            if (rootState.wizard.intolerance === 'Glutine') {
              intoleranceFilter =
                product.caratteristicheNutrizionali &&
                product.caratteristicheNutrizionali.includes(326);
            } else if (rootState.wizard.intolerance === 'Lattosio') {
              intoleranceFilter =
                product.caratteristicheNutrizionali &&
                (product.caratteristicheNutrizionali.includes(340) ||
                  product.caratteristicheNutrizionali.includes(309) ||
                  product.caratteristicheNutrizionali.includes(53) ||
                  product.caratteristicheNutrizionali.includes(54));
            }
          }

          return dietFilter && sportFilter && intoleranceFilter && targetFilter;
        });
        // commit('receiveSelectedProducts', resultProducts)
        commit('receiveProductsToPrint', productsToPrint);
        // console.log('products to print: ')
        // console.log(productsToPrint)
        return productsToPrint;
      })
      .catch((error) => {
        throw error;
      });
  },
  // selectProductsToPrint ({ state, commit, rootState }) {
  //   const productsToPrint = state.selectedProducts.filter(product => {
  //     return product.obiettivi && product.obiettivi.includes(rootState.wizard.target)
  //   })
  //   commit('receiveProductsToPrint', productsToPrint)
  // },
  addToWishList({ state, commit }, product) {
    if (state.wishList.find((x) => x.id === product.id)) {
      alert('product has been already added to your wishlist');
    } else {
      commit('pushToWishList', product);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
