<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="content" style="margin-top: 124px">
        <div class="container">
          <p style="text-align: center; font-size: 3rem; margin: 0">
            Per intolleranze gravi o allergie <br />ti invitiamo a rivolgerti al
            tuo medico
          </p>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="left">
          <div class="prev-button" @click="$router.push('/allergy-dialog')">
            <span>INDIETRO</span>
          </div>
        </div>
        <div class="center">
          <timer v-if="isDesktopApp" />
        </div>
        <div class="right">
          <div class="next-button active" @click="$router.push('/video-page')">
            <span>EXIT</span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Timer from '@/components/Timer';

export default {
  name: 'Allergy',
  components: {
    Timer,
  },
  computed: {
    ...mapGetters({
      hasAllergy: 'wizard/hasAllergy',
    }),
    isDesktopApp() {
      return process.env.VUE_APP_DESTINATION === 'app';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters['wizard/sex']) {
        next();
      } else {
        next('/landing');
      }
    });
  },
  methods: {
    ...mapMutations({
      selectHasAllergy: 'wizard/selectHasAllergy',
    }),
  },
};
</script>
