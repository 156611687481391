<template>
  <div id="wrapper">
    <main class="d-flex flex-column" style="min-height: 100vh">
      <div class="header">
        <div @click="$router.push('/profile')" class="back">< Back</div>
        <div class="title">Seleziona i prodotti</div>
        <div class="wishlist">
          <span @click="toWishListPage">my Wishlist</span>
          <div v-if="wishList.length" class="counter">
            {{ wishList.length }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="text-center">
          Sport:
          <span v-for="(sport, index) in selectedSports" :key="index">
            <span v-if="index > 0">, </span><span>{{ sport.name }}</span>
          </span>
        </div>
        <div class="text-center">
          Obiettivi:
          <span v-for="(aim, index) in selectedAims" :key="index">
            <span v-if="index > 0">, </span><span>{{ aim.name }}</span>
          </span>
        </div>
        <div class="grid">
          <div
            v-for="(product, index) in products"
            :key="index"
            class="grid-item grid-item-product"
          >
            <span @click="selectProduct(product)" v-b-modal.productModal>{{
              product.name || product.sku
            }}</span>
            <div @click="addToWishList(product)" class="button button-white">
              add to wishlist
            </div>
          </div>
        </div>

        <!-- Product Modal -->
        <b-modal
          v-if="selectedProduct"
          id="productModal"
          size="xl"
          :hide-footer="true"
          :title="selectedProduct.name"
        >
          <b-container fluid>
            <b-row>
              <b-col cols="6">
                <img
                  class="img-fluid"
                  :src="
                    'https://www.namedsport.com/it/pub/media/catalog/product/' +
                    selectedProduct.image
                  "
                  alt=""
                />
              </b-col>
              <b-col cols="6">
                <!--<p>{{ selectedProduct.name }}</p>-->
                <p v-html="selectedProduct.description"></p>
                <b-button
                  block
                  variant="outline-dark"
                  @click="addToWishList(selectedProduct)"
                  >Add to wishlist</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </b-modal>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'products-page',
  components: {},
  computed: {
    ...mapGetters({
      products: 'products/products',
      selectedProduct: 'products/selected',
      selectedSports: 'categories/selectedSports',
      selectedAims: 'categories/selectedAims',
      wishList: 'products/wishList',
    }),
  },
  methods: {
    ...mapMutations({
      selectProduct: 'products/selectProduct',
    }),
    ...mapActions({
      addToWishList: 'products/addToWishList',
    }),
    toWishListPage() {
      if (this.wishList.length > 0) {
        this.$router.push('/wishlist');
      }
    },
  },
};
</script>

<style lang="scss">
.header {
  display: flex;
  z-index: 1;
  .title {
    flex-grow: 1;
    text-align: center;
  }
  .back {
    min-width: 110px;
  }
  .wishlist {
    position: relative;
    padding-right: 16px;
    min-width: 110px;
    .counter {
      position: absolute;
      right: -10px;
      top: -10px;
      background: #3a61de;
      padding: 5px;
      color: #fff;
      border-radius: 50%;
      line-height: 1;
      font-size: 14px;
      width: 26px;
      height: 26px;
      text-align: center;
    }
  }
}
.grid {
  .grid-item-product {
    height: 150px;
    position: relative;
    .button {
      position: absolute;
      bottom: 1px;
      left: 1px;
      right: 1px;
    }
  }
}
.button {
  &.button-white {
    background: #fff;
    color: #000;
  }
}
.content {
  h2 {
    text-align: center;
  }
}
</style>
