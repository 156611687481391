import ua from 'universal-analytics';

const cleint = ua('UA-75266953-11');

const mutations = {};
const actions = {
  // eslint-disable-next-line no-empty-pattern
  trackEvent({}, { ec, ea, el, ev }) {
    cleint
      .event({
        ec,
        ea,
        el,
        ev,
      })
      .send();
  },
};
export default {
  mutations,
  actions,
  namespaced: true,
};
